import React from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const RutaPrivada = ({children}) => {

    const {globalAutenticado} = useAuth()

  return globalAutenticado 
  ? children
  : 
  <>
    <Navigate to='/' />
  </>
    
  
}

export default RutaPrivada