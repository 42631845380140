import { Modal } from "react-bootstrap"
import useLogos from "../../../../../hooks/useLogos";

const ModalTerminos = (props) => {
    const handleClose = () => props.close(false)
    const { logoCardif } = useLogos();

    return (
        <>
            <Modal show={props.show} onHide={handleClose} size="xl">
                <Modal.Header>
                    <div>
                        {logoCardif}
                    </div>
                    <div onClick={handleClose} style={{ paddingRight: '2rem', fontSize: '2rem' }}>
                        <p style={{ cursor: 'pointer' }}>X</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="body__terminos">
                        <p><b>Términos y condiciones</b></p><br/>
                        <p>
                            Bienvenido al sitio web de Cardif México Seguros de Vida, S.A. de C.V. / Cardif México Seguros Generales, S.A. de C.V. (en adelante, indistintamente, “Cardif”, o “Nosotros”.) Los términos “Usted” y “Usuario” según se utilizan, hacen referencia a toda persona y/o entidad que acceda al presente Sitio.
                            <br/>
                            <br/>
                            Por medio del presente documento, el Usuario tendrá acceso a toda aquella información relacionada con los términos y condiciones de uso del sitio web www.exploratuseguro.com (en adelante, el “Sitio”). Al aceptar estos términos y condiciones, el Usuario declara haberlos leído y comprender que éstos regirán el uso del Sitio. En caso de no aceptarlos o comprenderlos adecuadamente, el Usuario no podrá o deberá abstenerse de utilizar el Sitio, en caso contrario, reconoce y acepta que el uso del Sitio implica la aceptación de los presentes términos y condiciones de uso.
                            <br/>
                            <br/>
                            Estos términos y condiciones serán aplicables a todo usuario del Sitio. BNP PARIBAS CARDIF podrá realizar todo tipo de modificaciones al contenido, materiales e información que aparece sobre, o en relación con el Sitio, e interrumpir, suspender o terminar su funcionamiento en cualquier momento. Todo lo anterior será debidamente informado por BNP PARIBAS CARDIF.
                            <br/>
                            <br/>
                            Los términos "usted" y "usuario" según se utilizan aquí hacen referencia a toda persona y/o entidad que acceda al Sitio.
                            <br/>
                            <br/>
                            I. ASPECTOS GENERALES DE USO DEL SITIO
                            <br/>
                            <br/>
                            Usted podrá hacer uso del Sitio desde cualquier computador o dispositivo móvil que cuente con acceso a Internet, y cumpla con los requerimientos técnicos señalados en estos términos y condiciones. Cualquier persona puede acceder al sitio, sin embargo, para poder solicitar y hacer uso de los beneficios disponibles en el Sitio, deberá tener uno o más seguros asociados.
                            <br/>
                            <br/>
                            Toda la información que usted ingrese al Sitio deberá estar actualizada y ser veraz. Usted es el único responsable por mantener esa información al día, de manera tal que refleje su situación actual y real.
                            <br/>
                            <br/>
                            II. MECÁNICA DE FUNCIONAMIENTO DEL SITIO
                            <br/>
                            <br/>
                            Para acceder al Sitio, usted deberá, en primer lugar, registrarse. Para este registro, usted deberá proporcionar toda la información requerida por el Sitio. El uso y tratamiento de esta información por parte de BNP PARIBAS CARDIF se encuentra regulada por la legislación vigente en materia de seguros y por lo dispuesto en la Política de Privacidad del Sitio (disponible en www.mundowork.cl).
                            <br/>
                            <br/>
                            A través del Sitio, ponemos a su disposición, entre otras, las siguientes funcionalidades:
                            <br/>
                            <br/>
                            a. Utilizar los beneficios contemplados en la póliza de seguro que usted tenga contratada con BNP PARIBAS CARDIF.<br/>
                            b. Ponerse en contacto con nosotros;<br/>
                            c. Recuperar y modificar clave de acceso al sitio.
                            <br/>
                            <br/>
                            Asimismo, BNP PARIBAS CARDIF podrá, de tiempo en tiempo, agregar nuevas funcionalidades o actualizar/modificar aquellas ya existentes en el Sitio, lo que será oportunamente informado a nuestros usuarios.
                            <br/>
                            <br/>
                            Usted acepta que, salvo en caso de manifestar su oposición, las comunicaciones que reciba en relación con uso el Sitio le serán enviadas a la dirección de correo electrónico registrada en su perfil. Adicionalmente, al utilizar y registrarse en el Sitio, usted acepta que cualquier información, documento y otro antecedente que comparta con Nosotros será almacenado y registrado por BNP PARIBAS CARDIF para efectos de dar cumplimiento a la normativa vigente y sus obligaciones contractuales bajo las póliza de seguros que emite, y estarán a su disposición en caso de requerirse.
                            <br/>
                            <br/>
                            III. RESTRICCIONES
                            <br/>
                            <br/>
                            Para usar el Sitio, usted deberá tener, al menos, 18 años de edad. No están permitidas las cuentas de usuarios múltiples, falsos, o que utilicen sistemas automáticos para otros propósitos que no sean el uso estándar y personal de nuestro servicio. Se prohíbe a los usuarios violar o intentar violar la seguridad del Sitio.
                            <br/>
                            <br/>
                            El uso del Sitio es personal. No debe revelar su contraseña a terceros o desconocidos. Asimismo, usted no deberá permitir que un tercero haga uso del Sitio en nombre suyo. Por favor tenga presente que, en caso de no cumplir con estas restricciones, BNP PARIBAS CARDIF podrá eliminarlo como usuario del Sitio. De igual forma, BNP PARIBAS CARDIF no se hace responsable de los daños y perjuicios derivados de su incumplimiento de estas restricciones y de las disposiciones de estos términos y condiciones en general, particularmente en caso de que usted permita el uso de su cuenta de usuario por parte de un tercero. Asimismo, serán excluidos los usuarios que hayan facilitado datos falsos, sin perjuicio de las demás acciones que procedan en Derecho.
                            <br/>
                            <br/>
                            IV. REQUERIMIENTOS TÉCNICOS DEL SITIO.
                            <br/>
                            <br/>
                            Para acceder al Sitio y poder registrarse y utilizar sus servicios, será necesario contar con los siguientes requerimientos técnicos:
                            <br/>
                            <br/>
                            (i) Navegadores: Chrome – versión mínima 50; Edge – cualquier versión; Firefox – versión mínima 45; Safari – versión mínima 8; Internet Explorer – versión mínima 11.<br/>
                            (ii) Navegadores para dispositivos móviles: iOS Safari – versión 9.2; Android Chrome – versión 49.<br/>
                            (iii) Tamaño máximo para enviar archivos: 2 MB. (iv) Formatos aceptables de archivos: PNG, JPG, PDF.
                            <br/>
                            <br/>
                            V. RESPONSABILIDADES.
                            <br/>
                            <br/>
                            Cardif no es proveedor de servicios de Internet, por lo que no será responsable de las posibles fallas de conexión, problemas en el funcionamiento del Sitio por falta de o mala conexión a Internet, fallas de los equipos/dispositivos utilizados para conectarse al Sitio, y en general, de cualquier clase de falla o error que se deba a causas no imputables a Cardif. Cardif informa que en su sitio se podrán encontrar diversos links, URL o banners de terceros, sin embargo, no se hace responsable del contenido, veracidad y/o funcionalidad de dichos enlaces. Si el Usuario llegase a enlazarse en dichos sitios, estará aceptando sus propios términos y condiciones, bajo su exclusiva responsabilidad. Usted es el único responsable de conservar su nombre de usuario y clave, y de permitir cualquier acceso a su cuenta por parte de terceros.
                            <br/>
                            <br/>
                            El costo plan de datos y/o plan de Internet necesario para que usted tenga acceso al Sitio, son de su exclusiva responsabilidad y costo. Cardif no será responsable de la pérdida, hurto o robo del dispositivo mediante el cual usted accede al Sitio generado por su utilización en espacios públicos. Usted acuerda no hacer uso comercial no autorizado del Sitio.
                            <br/>
                            <br/>
                            VI. SERVICIOS DE TERCEROS
                            <br/>
                            <br/>
                            El listado de las asistencias y/o servicios contenidos en el Sito corresponden a la descripción de los beneficios entregados de forma exclusiva para clientes de Cardif que cuenten con un seguro respaldado por Nosotros. El uso de dichos servicios o asistencias es decisión del Usuario y sólo podrá utilizarse en caso de encontrarse al corriente del pago de la prima de su seguro. Cardif le informa que las asistencias y/o servicios son otorgados por terceros, no teniendo Cardif responsabilidad alguna por la realización de dichas asistencias y/o servicios por lo que no se hará cargo de ningún daño, directo o indirecto, que se pueda ocasionar con motivo o a causa del actuar negligente de dichos terceros.
                            <br/>
                            <br/>
                            VII. PROPIEDAD INTELECTUAL
                            <br/>
                            <br/>
                            Todos los derechos de propiedad intelectual sobre el Sitio son reservados y de propiedad exclusiva de Cardif, por lo tanto, el Usuario se compromete a no hacer un uso ilegal de estas obras. Está totalmente prohibida la venta, copia, alteración y/o distribución del contenido del Sitio, así como de las marcas, nombres, logos, contenidos en éste y/o de cualquiera de sus componentes. Los derechos sobre el Sitio están protegidos por leyes nacionales y tratados internacionales sobre propiedad intelectual. Cualquier reproducción o redistribución no autorizada está expresamente prohibida y puede conllevar sanciones civiles y penales.
                            <br/>
                            <br/>
                            VIII. PROTECCIÓN DE DATOS PERSONALES Y PRIVACIDAD
                            <br/>
                            <br/>
                            El Usuario acepta conocer las finalidades principales y secundarias del tratamiento de sus datos personales conforme al aviso de privacidad de Cardif, mismo que se pone a disposición del Usuario en www.bnpparibascardif.com.mx Para registrarse y utilizar el Sitio y las funcionalidades que en él se ofrecen, debe aceptar la política de privacidad del Sitio, disponible en www.viveprotegido.com
                            <br/>
                            <br/>
                            Cardif será el responsable del uso, resguardo y tratamiento de la información proporcionada por los Usuarios, lo anterior, de conformidad con lo señalado en la regulación mexicana vigente aplicable en materia de seguros, así como en lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, (en adelante “LFPDPPP”).
                            <br/>
                            <br/>
                            IX. CONTACTO
                            <br/>
                            <br/>
                            Si desea realizar consultas o comentarios acerca de estos términos y condiciones, o solicitar información adicional razonable sobre el Sitio y sus funcionalidades, escríbanos a sac_mx@cardif.com.mx o a través del teléfono 800 522 7343 de lunes a viernes en un horario de 8:00 a 21:00 horas y sábados de 9:00 a 18:00 horas.
                            <br/>
                            <br/>
                            X. JURISDICCIÓN
                            <br/>
                            <br/>
                            Los presentes términos y condiciones de uso serán interpretados y ejecutados de acuerdo con las leyes mexicanas, sometiéndose la resolución de cualquier duda, dificultad o controversia relacionada a la jurisdicción de los tribunales de la Ciudad de México.
                            <br/>
                            <br/>
                            Términos y condiciones actualizados al 21 de julio de 2023.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-12 d-flex justify-content-center">
                        <button className='btn__azul' onClick={handleClose}>Aceptar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalTerminos