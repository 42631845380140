import { IconAyuda, IconCorreo, IconTelefono } from '../../../../assets/icons/Icons'
import '../styles/Contacto.css'

const Contacto = () => {
    return (
        <>
            <div className="container-fluid contact mt-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 contact__container__card p-0">
                            <div className="contact__container">
                                <div className="row">
                                    <div className="col-12  col-lg-4">
                                        <div className="contact__item contact__item__border">
                                            <div className="contact__item__icon">
                                                <IconAyuda />
                                            </div>
                                            <div className="contact__item__text">
                                                <p className="contact__item__text__ayuda">¿Necesitas ayuda?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="contact__item contact__item__border">
                                            <div className="contact__item__icon">
                                                <IconTelefono />
                                            </div>
                                            <div className="contact__item__text">
                                                <p className="contact__item__text__tel">
                                                    <a href="tel:8005227343">800 522 7343</a>
                                                </p>
                                                <p>Horarios de atención: Lunes a viernes<br/>de 08:00 a 21:00 hrs.<br/>sábados de 09:00 a 18:00 hrs.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="contact__item contact__item__normal">
                                            <div className="contact__item__icon mt-2 mt-lg-0">
                                                <IconCorreo className="d-block d-lg-none" />
                                            </div>
                                            <div className="contact__item__text">
                                                <div className="d-none d-lg-flex">
                                                    <p className="contact__item__text__small ps-2"><a href="mailto:sac_mx@cardif.com.mx">sac_mx@cardif.com.mx</a><br/>Envia un correo electrónico.</p>
                                                </div>
                                                <p className="contact__item__text__small d-block d-lg-none"><a href="mailto:sac_mx@cardif.com.mx">sac_mx@cardif.com.mx</a><br/>Envia un correo electrónico</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contacto