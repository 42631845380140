import { useState } from "react"
import { Modal } from "react-bootstrap"
import SidebarLogin from "../../../../../components/sidebar/SidebarLogin"
import ButtonClose from "./ButtonClose"
import SpinnerCuadrado from "../../../../helpers/SpinnerCuadrado"

const ModalNewPasswordSuccess = (props) => {

    const handleClose = () => props.setShow(false)

    const [showLogin, setShowLogin] = useState(false)
    const [spinner, setSpinner] = useState(false)

    const openLogin = () => {
        handleClose()
        setShowLogin(true)
    }

    return (
        <>
            {
                spinner && <SpinnerCuadrado />
            }
            <SidebarLogin
                show={showLogin}
                setShow={setShowLogin}
                setShowModalSuccess={false}
                setSpinner={setSpinner}
            />

            <Modal show={props.show} onHide={handleClose} centered>
                <Modal.Body>
                    <ButtonClose close={handleClose} />
                    <div className='modal__success'>
                        <h2>Has cambiado tu contraseña</h2>
                        <p>Ahora puedes iniciar sesión con tus datos y tu nueva contraseña.</p>
                        <div className="mt-3" style={{ textAlign: 'center' }}>
                            <button className="btn__azul" onClick={openLogin}>INICIAR SESIÓN</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalNewPasswordSuccess