import { Routes, Route, useLocation } from "react-router-dom"
import HomeInScreen from "../modules/homein/principal/screens/HomeInScreen"
import ScrollToTop from "./ScrollToTop"
import { useEffect, useState } from "react"
import { CantidadIntentosCoursera } from "../services/coursera"
import { getToken } from "../modules/helpers/validations"
import ModalEncuestaCoursera from "../modules/homeout/principal/components/modal/ModalEncuestaCoursera"

const RouterHomeIn = () => {

    const [mostrarModalEncuesta, setMostrarModalEncuesta] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.loggedIn === true) {
                var cantidadIntentos = 0
                CantidadIntentosCoursera(getToken()).then(resp => {
                    cantidadIntentos = resp.data
                    if (cantidadIntentos <= 2) {
                        setTimeout(() => {
                            setMostrarModalEncuesta(true);
                        }, 15000)
                    }
                })
            }
        }
    }, [])

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/*" element={<HomeInScreen />} />
            </Routes>

            <ModalEncuestaCoursera
                show={mostrarModalEncuesta}
                close={setMostrarModalEncuesta}
            />
        </>
    )
}

export default RouterHomeIn