import { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import sha256 from 'sha256';
import useValidator from '../../hooks/useValidator';
import { encodeBase64, encryptDecrypt } from '../../modules/helpers/validations';
import { changePassword } from '../../services/serviceVivetuseguro';

const SidebarPasswordNewPass = ({errorData, mensaje, recaptchaRef, setMensaje, setSpinner, setErrorData, formCode, setCount, urlApi, codRecaptcha, handleClose, setShowModalSuccess}) => {

    const [formPass, setFormPass] = useState({
        id: "",
        code: "",
        password: "",
        passwordconfirm: "",
        tokenCaptcha: ""
    })

    const { formValidador, error } = useValidator(formPass.password)

    const handleChangePass = (e) => {
        setFormPass({
            ...formPass,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmitNuevaContrasena = async (e) => {
        e.preventDefault();

        if ((formPass.password === formPass.passwordconfirm) && !error) {

            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)

            let pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!,%,&,@,#,$,^,*,?,_,~,.,(,),/,-]).{7,15}/
            if (!pattern.test(formPass.passwordconfirm)) {
                setMensaje("La nueva contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.")
                setErrorData(true)
                setSpinner(false)
                // console.log('err 1')
                return
            }

            if (!pattern.test(formPass.password)) {
                setMensaje("La nueva contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.")
                setErrorData(true)
                setSpinner(false)
                // console.log('err 2')
                return
            }

            try {
                let objUsuario = {
                    id: formCode.id,
                    code: formCode.code,
                    password: encodeBase64(encryptDecrypt(sha256(formPass.passwordconfirm),
                        token.substring(token.length - 10, token.length)
                    )),
                    tokenCaptcha: token
                }

                // console.log({ objUsuario })
                changePassword(objUsuario).then(res => {
                    // console.log({ res })
                    setSpinner(false)
                    if (res.codigo === 0) {
                        setMensaje("")
                        setErrorData(false)
                        // console.log('Password cambiada con éxito')
                        handleClose()
                        setShowModalSuccess(true)
                        /*Swal.fire({
                            icon: 'success',
                            title: 'Contraseña actualizada con éxito'
                        })*/
                    } else {
                        setMensaje(res.mensaje)
                        setErrorData(true)
                    }
                })
            } catch (err) {
                setMensaje(err)
                setErrorData(true)
                setSpinner(false)
            }
        } else {
            // console.log('error de contraseñas')
            setErrorData(true)
            setMensaje("Las contraseñas deben ser iguales")
        }
    }

    return (
        <>
            <div className="body__login">
                <h2>Crea tu nueva contraseña</h2>
                <p>Ingresa tu nueva contraseña para que puedas acceder a tus beneficios.</p>
                {
                    errorData &&
                    <div className='error__box'>
                        <div className='texto__error'>
                            <p>{mensaje}</p>
                        </div>
                    </div>
                }
                <Form autoComplete="off" onSubmit={(e) => { e.preventDefault()}} className="form__modal" >
                    <div className="row justify-content-center">
                        <div className='col-12'>
                            <input
                                type='password'
                                className='form-control border-input '
                                id='password'
                                name='password'
                                placeholder='Contraseña'
                                autoComplete="off"
                                value={formPass.password}
                                onChange={handleChangePass}
                            />
                        </div>
                        <div className='col-12'>
                            <input
                                type='password'
                                className='form-control border-input '
                                id='passwordconfirm'
                                name='passwordconfirm'
                                placeholder='Confirma tu contraseña'
                                autoComplete="off"
                                value={formPass.passwordconfirm}
                                onChange={handleChangePass}
                            />
                        </div>

                        {formValidador()}

                        <div className='col-12 mt-5 text-center'>
                            <button className="btn__azul" onClick={handleSubmitNuevaContrasena}>CREAR CONTRASEÑA</button>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={codRecaptcha}
                            />
                        </div>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default SidebarPasswordNewPass