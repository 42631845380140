import { useState } from "react";
import SidebarLogin from "./SidebarLogin";
import SpinnerCuadrado from "../../modules/helpers/SpinnerCuadrado";

const SidebarButton = ({setShowModalSuccess}) => {

    const [show, setShow] = useState(false);
    const [spinner, setSpinner] = useState(false)

    const handleShow = () => setShow(true);

    return (
        <>
            {
                spinner && <SpinnerCuadrado />
            }
            <div className="sticky__btn__sidebar d-lg-block d-none">
                <button className="btn__sidebar" type="button" onClick={handleShow} >
                    <b>INGRESA A TU<br/>ECOSISTEMA AQUÍ</b>
                    <i className="fa-solid fa-angle-up flecha__sticky__btn"></i>
                </button>

                <SidebarLogin
                    show={show}
                    setShow={setShow}
                    setShowModalSuccess={setShowModalSuccess}
                    setSpinner={setSpinner}
                />

            </div>
        </>
    )
}

export default SidebarButton