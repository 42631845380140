let gate = "api";
//let host = "localhost:44311"
//let host = "apimelimx.komased.com"
let host = process.env.REACT_APP_API //PRE-PRODUCCION
//let host = "api.exploratuseguro.com" //PRODUCCION

export const URL_BACKEND_AUTHS = `https://${host}/${gate}`

export const COD_RECAPTCHA_V3 = '6Le4UuwnAAAAAGqEcWNmr6klJ-dzidxTiP7SgK3T'

export const CLI_PUB = '8f6391895c404ab68eb7a8e42eb73587'

export const user = 'CARDIF';

export const password = '9B9CB6631B27AA1DF47DB351B497004810015B09';