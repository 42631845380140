import PolizaCards from "../components/PolizaCards"
import '../styles/poliza.css'

const PolizaScreen = ({ setSpinner }) => {

    return (
        <>
            <div className="poliza__div">
                <h1><b>COURSERA</b></h1>

                <PolizaCards setSpinner={setSpinner} />
            </div>
        </>
    )
}

export default PolizaScreen