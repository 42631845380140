import { useContext } from "react"
import AuthContext from "../../context/auth/authContext"

const HeaderUser = () => {

    const {globalCerrarSesion} = useContext(AuthContext)

    const cerrarSesion = () => {
        globalCerrarSesion()
    }

    return (
        <>
            <div>
                <button className="btn__cerrar-sesion" onClick={cerrarSesion}>CERRAR SESIÓN</button>
            </div>
        </>
    )
}

export default HeaderUser