import { useContext, useState } from "react"
import ModalCoursera from "../../../homeout/principal/components/modal/ModalCoursera"
import AuthContext from "../../../../context/auth/authContext"

const PolizaCards = ({setSpinner}) => {

    const {globalDataUser} = useContext(AuthContext)
    const [showModalCoursera, setShowModalCoursera] = useState(false)

    return (
        <>
            <ModalCoursera
                show={showModalCoursera}
                close={setShowModalCoursera}
                OrigenIdSocio={globalDataUser.globalOrigenIdSocio}
                setSpinner={setSpinner}
            />
            
            <section className='pol__cards-in'>
                <div className="poliza__cards-in">
                    <div className="pol__card">
                        <div>
                            <div className='pol__card-img'>
                                <img className='pol__card-icoprincipal' src='/assets/img/homein/poliza/img-coursera.png' alt="imagen cob" />
                                <div className='pol__card-ico_container ' >
                                    <img className={`pol__card-ico`} src='/assets/img/homein/poliza/ico-coursera.svg' alt="imagen coursera icono" />
                                </div>
                            </div>
                            <div className="pol__card-texto">
                                <div className="pol__card-superior">
                                    <h4 style={{ textAlign: 'center', marginBottom: '1em' }} className={`pol__card-title`}>Coursera</h4>
                                    <p style={{ fontSize: '1.5rem', lineHeight: '20px' }} className='pol__card-descripcion'>Estás a un paso de unirte a millones de profesionales que están logrando sus metas con Coursera</p>
                                </div>
                            </div>
                        </div>
                        <div className="pol__card-btn" >
                            <div
                                className='btn__azul'
                                onClick={() => setShowModalCoursera(true)}
                            >
                                COMIENZA AHORA
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PolizaCards