const BeneficiosCard = ({image, icon, title, text}) => {
    return (
        <>
            <div className="col-12 col-sm-7 col-md-4 col-lg-3 mb-3 beneficios__card__width">
                <div className="beneficios__card">
                    <div className="beneficios__card__image">
                        <img src={image} alt="Imagen Cursos Online" className="img-fluid" />
                    </div>
                    <div className="beneficios__card__title">
                        <img src={icon} style={{ width: '15%' }} alt="Icono Card" />
                        <h3 className="beneficios__card__title__head" dangerouslySetInnerHTML={{__html: title}}></h3>
                    </div>
                    <div className="beneficios__card__text">
                        <p>{text}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BeneficiosCard