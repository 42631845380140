import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import '@trendmicro/react-sidenav/dist/react-sidenav.css'
import { useState } from 'react'
import "../coursera/styles/sidebarHomeIn.css"
import ModalPoliticaCookies from '../../homeout/principal/components/modal/ModalPoliticaCookies'
import ModalTerminos from '../../homeout/principal/components/modal/ModalTerminos'
import ModalAvisoPublicidad from '../../homeout/principal/components/modal/ModalAvisoPrivacidad'
import { useContext } from 'react'
import AuthContext from '../../../context/auth/authContext'
import { IconArrowSidebarClose, IconArrowSidebarOpen } from '../../../assets/icons/Icons'

const SidebarHomeIn = () => {

    const [showTitle, setShowTitle] = useState(false)
    const [showFooter, setShowFooter] = useState(false)

    const [showPoliticaCookies, setShowPoliticaCookies] = useState(false)
    const [showTerminos, setShowTerminos] = useState(false)
    const [showAvisoPublicidad, setShowAvisoPublicidad] = useState(false)

    const {globalDataUser} = useContext(AuthContext)

    const [stateArrowSidebar, setStateArrowSidebar] = useState(false);

    const onToggle = () => {
        setShowTitle(!showTitle)
        setShowFooter(!showFooter)
        setStateArrowSidebar(!stateArrowSidebar)
        if (!stateArrowSidebar) {
            document.getElementsByClassName('poliza__div')[0].style.padding = "4rem 35rem"
        } else {
            document.getElementsByClassName('poliza__div')[0].style.padding = "4rem 15rem"
        }
    }

    return (
        <>
            <ModalPoliticaCookies
                show={showPoliticaCookies}
                close={setShowPoliticaCookies}
            />

            <ModalTerminos
                show={showTerminos}
                close={setShowTerminos}
            />

            <ModalAvisoPublicidad
                show={showAvisoPublicidad}
                close={setShowAvisoPublicidad}
            />      

            <SideNav
                onToggle={onToggle}
                className={`sidenav__homein ${stateArrowSidebar ? "sidenav__homein__expanded" : "sidenav__homein__collapsed"}`}
            >
                <div style={{ position: 'absolute', top: '11.8rem' }} onClick={onToggle} className={`homein__sidebar__button_close`}>
                    {
                        stateArrowSidebar ? <IconArrowSidebarClose /> : <IconArrowSidebarOpen />
                    }
                </div>
                {   
                    showTitle &&
                    <div className='div__titulo__sidenav'>
                        <h2>¡Hola <br/>{globalDataUser.globalUsuName}!</h2>
                    </div>
                }
                <Nav defaultSelected="coursera">
                    <NavItem eventKey="coursera" className="sidenav__item">
                        <NavIcon style={{ paddingLeft: '1rem', paddingRight: '6rem' }}>
                            <img className="ico__sidenav__coursera" src='/assets/img/homein/poliza/ico-coursera.svg' alt="imagen coursera icono" />
                            {
                                stateArrowSidebar && <span style={{ color: '#000000', fontFamily: 'GT-America-Bold', paddingLeft: '1rem' }} >COURSERA</span>
                            }
                        </NavIcon>
                        <NavText style={{ color: '#000000', fontFamily: 'GT-America-Bold' }}>
                            COURSERA
                        </NavText>
                    </NavItem>
                </Nav>
                {
                    showFooter &&
                    <div className='div__footer__sidenav'>
                        <hr className='linea__footer'></hr>
                        <p onClick={() => setShowPoliticaCookies(true)}>Política de cookies</p>
                        <p onClick={() => setShowTerminos(true)}>Términos y condiciones</p>
                        <p onClick={() => setShowAvisoPublicidad(true)}>Aviso de privacidad</p>
                        <div style={{ paddingTop: '1rem' }}>® Cardif México - Todos los derechos reservados</div>
                    </div>
                }
            </SideNav>
        </>
    )
}

export default SidebarHomeIn