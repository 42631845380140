import { useContext } from "react"
import AuthContext from "../context/auth/authContext"

const useAuth = () => {

    const { globalAutenticado,globalDataUser,globalCerrarSesion } = useContext(AuthContext)

    return {globalAutenticado,globalDataUser,globalCerrarSesion}

}

export default useAuth