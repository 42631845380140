import { useState } from "react"
import useLogos from "../../hooks/useLogos"
import ModalAvisoPublicidad from "../../modules/homeout/principal/components/modal/ModalAvisoPrivacidad"
import ModalPoliticaCookies from "../../modules/homeout/principal/components/modal/ModalPoliticaCookies"
import ModalTerminos from "../../modules/homeout/principal/components/modal/ModalTerminos"
import "../../styles/footer.css"
import "../../modules/homeout/principal/components/modal/modal.css"
import useAuth from "../../hooks/useAuth"

const Footer = () => {

    const { logoCardif } = useLogos()

    const { globalAutenticado } = useAuth()

    const [showPoliticaCookies, setShowPoliticaCookies] = useState(false)
    const [showTerminos, setShowTerminos] = useState(false)
    const [showAvisoPublicidad, setShowAvisoPublicidad] = useState(false)

    return (
        <>
            <ModalPoliticaCookies
                show={showPoliticaCookies}
                close={setShowPoliticaCookies}
            />

            <ModalTerminos
                show={showTerminos}
                close={setShowTerminos}
            />

            <ModalAvisoPublicidad
                show={showAvisoPublicidad}
                close={setShowAvisoPublicidad}
            />
            
            {
                !globalAutenticado &&
                <div className="container-fluid footer">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-4 col-xl-3">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 text-center d-flex footer__logo__div">
                                    {logoCardif}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 col-xl-9 mt-4">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-lg-3 col-xl-2 text-center footer__div__text">
                                    <p onClick={() => setShowPoliticaCookies(true)} className="footer__text">Política de cookies</p>
                                </div>
                                <div className="col-12 col-lg-3 col-xl-2 text-center footer__div__text">
                                    <p onClick={() => setShowTerminos(true)} className="footer__text">Términos y condiciones</p>
                                </div>
                                <div className="col-12 col-lg-3 col-xl-2 text-center footer__div__text">
                                    <p onClick={() => setShowAvisoPublicidad(true)} className="footer__text">Aviso de privacidad</p>
                                </div>
                                <div className="col-12 col-lg-3 col-xl-4 text-center">
                                    <p className="footer__text footer__text__bnb">® Cardif México - Todos los derechos reservados</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Footer