import { useContext, useEffect,useState } from "react"
import HeadHomeOut from "../components/HeadHomeOut"
import SidebarButton from "../../../../components/sidebar/SidebarButton"
import ModalNewPasswordSuccess from "../components/modal/ModalNewPasswordSuccess"
import SidebarLogin from "../../../../components/sidebar/SidebarLogin"
import Beneficios from "../components/Beneficios"
import Requisitos from "../components/Requisitos"
import "../styles/Requisitos.css"
import "../styles/Beneficios.css"
import Contacto from "../components/Contacto"
import AuthContext from "../../../../context/auth/authContext"
import { useNavigate } from "react-router-dom"
import SpinnerCuadrado from "../../../helpers/SpinnerCuadrado"

const HomeOutScreen = () => {

    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const { globalAutenticado } = useContext(AuthContext)
    const navigate = useNavigate()
    
    useEffect(() => {
        if (globalAutenticado) {
            navigate('/ecosistema')
        }
        
    },[]);

    return (
        <>
            {/* <div>
                <main> */}
                    {
                        spinner && <SpinnerCuadrado />
                    }

                    <ModalNewPasswordSuccess
                        show={showModalSuccess}
                        setShow={setShowModalSuccess}
                    />

                    <SidebarLogin
                        show={showLogin}
                        setShow={setShowLogin}
                        setShowModalSuccess={setShowModalSuccess}
                        setSpinner={setSpinner}
                    />

                    <SidebarButton setShowModalSuccess={setShowModalSuccess} />
                    <HeadHomeOut showLogin={showLogin} setShowLogin={setShowLogin} />
                    <Beneficios showLogin={showLogin} setShowLogin={setShowLogin} />
                    <Requisitos showLogin={showLogin} setShowLogin={setShowLogin} />
                    <Contacto />
                {/* </main>
            </div> */}
        </>
    )
}

export default HomeOutScreen