export const programasCoursera = [
    {
        id: 1,
        urlCoursera: 'https://www.coursera.org/programs/eco-coursera-b971m?attemptSSOLogin=true&authMode=login',
        ssoattribute: 'ppalmelimx'
    },
    {
        id: 2,
        urlCoursera: 'https://www.coursera.org/programs/desarrollo-profesional-s-ja3ox?attemptSSOLogin=true&authMode=login',
        ssoattribute: 'cetelem-001'
    }
]
