import Axios from "axios";
import * as CryptoJS from "crypto-js";
import { CLI_PUB, URL_BACKEND_AUTHS } from "../environment/variables";

export function getToken() {
    return CryptoJS.AES.decrypt(localStorage.getItem('token'),CLI_PUB).toString(CryptoJS.enc.Utf8);
  }
  
export function isActive() {
    return window.localStorage.getItem("token") ? true : false;
}
export function getUser() {
    if (localStorage.getItem('user') === null) {
        return null
    }
    return JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('user'),CLI_PUB).toString(CryptoJS.enc.Utf8))
}

export function closeSession() {
    localStorage.clear();
    window.location.reload();
}

export function addLogs(pagina, control, extra) {
    var logUser = {
      idUsuario: 0,
      ciudad_localizacion: "",
      fechaServidor: "",
      horaServidor: "",
      fechaLocal: "",
      horaLocal: "",
      direccion_ip: "",
      pagina: pagina,
      control: control,
      extra: extra,
    };
    if (getUser() === null) {
      logUser.idUsuario = 0;
    } else {
      logUser.idUsuario = getUser().idUsuario;
    }
    let date = new Date();
    logUser.fechaLocal = `${date.getDate()}/${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()}`;
    logUser.horaLocal = `${
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
    }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
    Axios.get(`https://geomok.grupomok.com.co/json`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((succ) => {
        logUser.direccion_ip = succ.data.ip;
        logUser.ciudad_localizacion = succ.data.city;
        Axios.post(`${URL_BACKEND_AUTHS}/logUser/inserta`, JSON.stringify(logUser), {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((success) => {
            if (pagina === "logout") {
              getOutSession();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("EERROR ??", error);
      });
  }
  
  const getOutSession = () => {
    closeSession();
    setTimeout(() => {
      console.log(isActive());
    }, 2000);
  };