import { NavLink } from "react-router-dom"
import useLogos from "../../hooks/useLogos"
import '../../styles/header.css'
import '../../styles/index.css'
import HeaderMenu from "./HeaderMenu"
import useAuth from "../../hooks/useAuth"

const Header = () => {

    const { logoExploraSeguro } = useLogos()

    const { globalAutenticado } = useAuth()

    return (
        <div className="sticky-top">
            <div className="container-fluid header">
                <div className="home__header">
                    <div className="home__logo">
                        <NavLink to={'/'}>
                            {logoExploraSeguro}
                        </NavLink>
                    </div>
                    <HeaderMenu globalAutenticado={globalAutenticado}/>
                </div>
            </div>
        </div>
    )
}

export default Header