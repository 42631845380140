const useLogos = () => {

    const logoVivetuseguro = 
        <div className="separador__izquierdo">
            <img src="/assets/img/header/img_vivetuseguro_logo.webp" alt="logo_vivetuseguro"/>
        </div>

    const logoCardif = 
        <div>
            <img src="/assets/img/header/logo_cardif.svg" className="logo__cardif" alt="logo_cardif"/>
        </div>

    const logoExploraSeguro = 
        <div>
            <img src="/assets/img/header/logo_explora.webp" className="logo__explora" alt="logo__explora"/>
        </div>
    
    return {logoVivetuseguro, logoCardif, logoExploraSeguro}
}

export default useLogos