import { BrowserRouter } from 'react-router-dom'
import './App.css'
import RouterMain from './routers/RouterMain';
import AuthState from './context/auth/authState';
//import AuthState from './context/auth/authState';
import "bootstrap/dist/js/bootstrap.min.js"

function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <AuthState>
            <RouterMain />
          </AuthState>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App
