import { Modal } from "react-bootstrap"

const ModalExpiredSession = ({ show, close }) => {

    const handleClose = () => {
        close(false)
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="body__cierre__session">
                    <h2>Tu sesión ha expirado</h2>
                    <p>Hemos cerrado tu sesión por inactividad.</p>
                    <button className="btn__azul" onClick={handleClose}>Aceptar</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalExpiredSession