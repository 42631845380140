import React, { useEffect, useReducer } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import AuthContext from "./authContext"
import AuthReducer from "./authReducer"
import { CLI_PUB } from "../../environment/variables"
import { getDataUser, getIdUser, isactive } from "../../modules/helpers/validations"
import * as CryptoJS from "crypto-js"
import TagManager from "react-gtm-module/dist/TagManager"

const AuthState = (props) => {

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [state, dispatch] = useReducer(AuthReducer, {
        globalDataUser: {
            globalUsuId: "",
            globalUsuName: "",
            globalUsuSurnameMat: "",
            globalUsuSurname: "",
            globalUsuCellphone: "",
            globalUsuEmail: "",
            globalUsuTipoDoc: "",
            globalDocIdentidad: "",
            globalUsuRole: "",
            globalInvitado: "",
            globalNroInvitados: 0,
            globalOrigenIdSocio: "",
        },
        globalModulos: {
            modPFinanciera: false,
            modEmpleabilidad: false,
            modEmprendimiento: false,
            modSalud: false,
            modVida: false,
            modCancer: false,
            modAccidentes: false,
            modProteccionFinanciera: false,
            modVidaPlus: false,
            modPFinancieraElemental: false,
            modCoursera: false,
        },
        /*globalEcosistemas: ecosistemas,
        globalDetalleEcosistemas: detalleEcosistemas,
        globalEcosistemaActivo: ecosistemas[0]?.id,*/
        globalToken: "",
        globalTokenUser: "",
        globalAutenticado: false, // por defecto debe ser FALSE
        globalCargando: true, // por defecto debe ser TRUE
        globalVentanaCierre: false,
        globalStatusEncuesta: true
    })

    // Listado de Funciones globales:
    const globalIniciarSesion = (login) => {

        dispatch({
            action: 'INICIAR_SESION',
            data: login
        })
    }

    const globalActualizarEcosistemaActivo = (idEcosistema) => {
        dispatch({
            action: 'ACTUALIZAR_ECOSISTEMA_ACTIVO',
            data: idEcosistema
        })
    }

    const globalDesactivaVentanaCierre = () => {
        dispatch({
            action: 'DESACTIVA_VENTANA_CIERRE',
        })
    }

    const globalInactivaSesion = () => {
        localStorage.clear()
        sessionStorage.clear()
        llamaTagManager()

        dispatch({
            action: 'INACTIVA_SESION',
        })
        navigate('/iniciasesion')
    }

    const llamaTagManager = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'cerrarSesion',
            }
        })
    }

    const globalActualizarEncuesta = (estado) => {
        dispatch({
            action:'ACTUALIZAR_ENCUESTA',
            data: estado===1
        })
    }

    const globalCerrarSesion = () => {
        localStorage.clear()
        sessionStorage.clear()
        llamaTagManager()

        dispatch({
            action: 'CERRAR_SESION',
        })
        // console.log('cierre de sesion')
        navigate('/')
    }

    const validaLogueo = () => {
        if (isactive()) {
            let dataUser = getDataUser()
            let idUser = getIdUser()
            let token = localStorage.getItem('token')

            globalIniciarSesion({
                globalDataUser: {
                    globalUsuId: idUser,
                    globalUsuName: dataUser.nombres,
                    globalUsuSurnameMat: dataUser.apellidoMaterno,
                    globalUsuSurname: dataUser.apellidoPaterno,
                    globalUsuCellphone: dataUser.numeroCelular,
                    globalUsuEmail: dataUser.correoElectronico,
                    globalUsuTipoDoc: dataUser.dicTipoDocumento,
                    globalDocIdentidad: dataUser.identificacion,
                    globalUsuRole: "",
                    globalInvitado: dataUser.nroinvitado>0,
                    globalNroInvitados: dataUser.nroinvitado,
                    globalOrigenIdSocio: dataUser.OrigenIdSocio,
                },
                //globalModulos: getCoberturasGlobal(getCoberturas()),
                globalToken: token,
                globalTokenUser: CryptoJS.AES.encrypt(JSON.stringify(dataUser), CLI_PUB),
                globalAutenticado: true,
                globalCargando: false

            })
            if (pathname.includes('/ecosistema')) {
                navigate(pathname)
            } else {
                navigate('/ecosistema')
            }
        }
    }

    const globalActualizarNroInvitados = (nroInvitados) => {
        dispatch({
            action: 'ACTUALIZAR_INVITADOS',
            data: nroInvitados
        })
    }

    const globalActualizarDatosUsuario = (dataUsuario) => {
        dispatch({
            action: 'ACTUALIZAR_USUARIO',
            data: dataUsuario
        })
    }

    const globalActualizarDocumentoUsuario = (documento) => {
        dispatch({
            action: 'ACTUALIZAR_DOCUMENTO_USUARIO',
            data: documento
        })
    }

    useEffect(() => {
        validaLogueo()
        // console.log({invi: getDataUser().nroinvitado})
    }, [])

    return (
        <AuthContext.Provider value={{
            globalDataUser: state.globalDataUser,
            globalSponsor: state.globalSponsor,
            globalContrato: state.globalContrato,
            globalModulos: state.globalModulos,
            globalToken: state.globalToken,
            globalTokenUser: state.globalTokenUser,
            globalAutenticado: state.globalAutenticado,
            globalCargando: state.globalCargando,
            globalEcosistemas: state.globalEcosistemas,
            globalDetalleEcosistemas: state.globalDetalleEcosistemas,
            globalEcosistemaActivo: state.globalEcosistemaActivo,
            globalVentanaCierre: state.globalVentanaCierre,
            globalStatusEncuesta: state.globalStatusEncuesta,
            globalIniciarSesion,
            globalActualizarEcosistemaActivo,
            globalCerrarSesion,
            globalInactivaSesion,
            globalDesactivaVentanaCierre,
            globalActualizarNroInvitados,
            globalActualizarDatosUsuario,
            globalActualizarEncuesta,
            globalActualizarDocumentoUsuario
        }}>
            {props.children}
        </AuthContext.Provider>
    )

}

export default AuthState