import React, { useEffect, useState } from 'react'
import { controlFormLogin } from '../modules/helpers/validator'

const useValidator = (password) => {

    const [validador, setValidador] = useState({
        longitud: 0,
        textos: 0,
        especiales: 0
    })

    const [iniciaValidador, setIniciaValidador] = useState(false)

    let resutaldo = validador.longitud + validador.textos + validador.especiales

    const validaPassword = () => {
        let regLongitud = controlFormLogin[6]?.regex.test(password)
        let regTextos = controlFormLogin[7]?.regex.test(password)
        let regEspeciales = controlFormLogin[8]?.regex.test(password)

        setValidador({
            longitud: regLongitud ? 1 : 0,
            textos: regTextos ? 1 : 0,
            especiales: regEspeciales ? 1 : 0
        })

        if (password !== '') {
            setIniciaValidador(true)
        } else {
            setIniciaValidador(false)
        }
    }

    useEffect(() => {
        validaPassword()
        //console.log(iniciaValidador, password)
    }, [password])


    const formValidador = () => {
        return (
            <div className="validador__container" style={{ minHeight: '40px' }}>
                {iniciaValidador &&
                    <div className="auth__validador-resumen">
                        <div className="validador__franjas">
                            <div className={`validador__franja ${resutaldo === 3 ? 'verde' : resutaldo === 2 ? 'amarillo' : 'rojo'}`}></div>
                            {/* <div className={`validador__franja amarillo ${resutaldo === 3 ? 'verde' : resutaldo === 2 ? 'amarillo' : 'rojo'} `}></div>
                            <div className={`validador__franja amarillo ${resutaldo === 3 ? 'verde' : resutaldo === 2 ? 'amarillo' : 'rojo'} `}></div> */}
                            <div className={`validador__franja ${resutaldo === 3 ? 'verde' : resutaldo === 2 ? 'amarillo' : ''} `}></div>
                            <div className={`validador__franja ${resutaldo === 3 ? 'verde' : resutaldo === 2 ? '' : ''} `}></div>
                        </div>
                        <div className="validador__coment">Contraseña {resutaldo === 3 ? 'alta' : (resutaldo === 2 ? 'media' : 'baja')}</div>
                    </div>
                }

                <div className="auth__validador-detalle">
                    <div>Recuerda debe contener:</div>
                    <div className="validador__detalle">
                        <div className="validador__detalle-item">
                            <i className={`fa-solid fa-circle-check ${validador.longitud ? 'verde' : 'plomo'}`}></i>
                            <p>8 a 16 caracteres</p>
                        </div>
                        <div className="validador__detalle-item">
                            <i className={`fa-solid fa-circle-check ${validador.textos ? 'verde' : 'plomo'}`}></i>
                            <p>Combinación de letras y números</p>
                        </div>
                        <div className="validador__detalle-item plomo">
                            <i className={`fa-solid fa-circle-check ${validador.especiales ? 'verde' : 'plomo'}`}></i>
                            <p>Caracteres especiales como !"@*?¿#/()</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    return { formValidador, error: resutaldo !== 3 }
}

export default useValidator