import { useState } from "react"
import PolizaScreen from "../../coursera/screens/PolizaScreen"
import SidebarHomeIn from "../../sidebar/SidebarHomeIn"
import SpinnerCuadrado from "../../../helpers/SpinnerCuadrado"
import { useEffect } from "react"
import { refreshTokenSession } from "../../../helpers/sessions"

const HomeInScreen = () => {

    const [spinner, setSpinner] = useState(false)

    useEffect(() => {
        refreshTokenSession()
    }, [])

    return (
        <>
            {
                spinner && <SpinnerCuadrado />
            }

            <SidebarHomeIn />
            <PolizaScreen setSpinner={setSpinner} />
        </>
    )
}

export default HomeInScreen