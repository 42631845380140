import { Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendCode } from '../../services/serviceVivetuseguro';
import { useState } from 'react';
import Timer from '../timer/Timer';
import SidebarLogin from './SidebarLogin';
import { addLogs } from '../../services/logs_service';

const SidebarPasswordCodigo = ({errorData, mensaje, formCode, recaptchaRef, setMensaje, setSpinner, form, setErrorData, setCount, setFormCode, urlApi, codRecaptcha, close}) => {

    const handleSubmitCodigo = async (e) => {
        e.preventDefault();

        let codeOtp = parseInt(codigo[0] + codigo[1] + codigo[2] + codigo[3]);
        formCode.code = codeOtp
        setFormCode(formCode)

        if (formCode.code !== "") {

            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)

            let objUsuario = {
                id: formCode.id,
                code: formCode.code,
                tokenCaptcha: token
            }

            addLogs('login', 'Intento Recuperar contraseña código', form.identificacion);

            try {
                // console.log({ objUsuario })
                sendCode(objUsuario).then(res => {

                    // console.log({ res })
                    setSpinner(false)
                    if (res.codigo === 0) {
                        addLogs('login', 'Recuperar contraseña código Exitoso', form.identificacion);
                        setMensaje("")
                        setErrorData(false)
                        setCount(3)
                    } else {
                        addLogs('login', 'Error Recuperar contraseña código', form.identificacion);
                        setMensaje('Código inválido')
                        setErrorData(true)
                    }
                })
            } catch (err) {
                setMensaje(err)
                setErrorData(true)
                setSpinner(false)
            }
        } else {
            setMensaje("Debes proporcionar un código.")
        }
    }

    const [codigo, setCodigo] = useState(["","","",""]);
    const [endTimerStatus, setEndTimerStatus] = useState(false)
    const [timeCode, setTimeCode] = useState(60000)
    const [showLogin, setShowLogin] = useState(false)

    const endTimer = () => {
        setEndTimerStatus(true)
    }

    const handleSetTimer = (t)=> {
        setTimeCode(t)
    }

    const hideCelular = (celular) => {
        let hiddenCelular = ''
        for (var i = 0; i < celular.length; i++) {
            if (i < 2 || i === celular.length - 1) {
                hiddenCelular += celular[i]
            } else hiddenCelular += '*'
        }
        return hiddenCelular
    }

    const handleChangeInputs = (element, index) => {
        if (isNaN(element.value)) return false;
        setCodigo([...codigo.map((d, idx) => (idx === index ? element.value : d))]);
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const resendCodigo = () => {
        setEndTimerStatus(false)
        setTimeCode(60000)
    }

    const gotoLogin = () => {
        setShowLogin(true)
        close()
    }


    return (
        <>
            <SidebarLogin
                show={showLogin}
                setShow={setShowLogin}
            />

            <div className="body__login">
                <h2>Código de verificación</h2>
                <p>Ingresa el código de verificación que te enviamos a tu celular {hideCelular(form.identificacion)}</p>
                {
                    errorData &&
                    <div className='error__box'>
                        <div className='texto__error'>
                            <p>{mensaje}</p>
                        </div>
                    </div>
                }
                <Form autoComplete="off" onSubmit={(e) => { e.preventDefault()}} className="form__modal" style={{ paddingBottom: '1rem' }}>
                    <div className="row justify-content-center">
                        <div className="campos__codigo">
                            {
                                codigo.map((data, index) => {
                                    return (
                                        <input
                                            className="codigo-field"
                                            type="text"
                                            name="code"
                                            key={index}
                                            value={data}
                                            maxLength="1"
                                            onChange={e => handleChangeInputs(e.target, index)}
                                            onFocus={e => e.target.select()}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div style={{ textAlign: 'center' }} className='col-12 mt-3 pt-5 mb-4'>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={codRecaptcha}
                            />
                            <button className="btn__azul" onClick={handleSubmitCodigo}>CONFIRMAR CÓDIGO</button>
                        </div>
                    </div>
                </Form>
                <div className='timer__codigo'>
                    <Timer mill={timeCode} end={endTimer} status={endTimerStatus} handleSetTimer={handleSetTimer} />
                    {
                        endTimerStatus &&
                        <span className='reenviar__codigo' onClick={resendCodigo}>Reenviar código</span>
                    }
                    <br/>
                    {/* <div style={{ paddingTop: '2rem' }}>
                        <span className='span__inicio__sesion' onClick={gotoLogin}>
                            Iniciar sesión
                        </span>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default SidebarPasswordCodigo