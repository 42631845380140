import * as CryptoJS from "crypto-js";
import { Buffer } from 'buffer';
import { CLI_PUB } from "../../environment/variables";

export function encryptDecrypt(password, keyPass) {
     var key = keyPass.split("");
    var output = [];
  
    for (var i = 0; i < password.length; i++) {
      var charCode = password.charCodeAt(i) ^ key[i % key.length].charCodeAt(0);
      output.push(String.fromCharCode(charCode));
    }
    return output.join("");
}


export function encodeBase64(data){
  return Buffer.from(data).toString('base64');
}

export function isactive(){
  return localStorage.getItem('token')&&localStorage.getItem('user')?true:false; 
}

export function getDataUser(){
  return JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('user'),CLI_PUB).toString(CryptoJS.enc.Utf8));
}

export function getIdUser(){
  return Number(Buffer.from(getDataUser().idUsuario, 'base64').toString('ascii'))
}

export function getToken(){
  return CryptoJS.AES.decrypt(localStorage.getItem('token'),CLI_PUB).toString(CryptoJS.enc.Utf8);
}

export function getToken2(){
  return JSON.parse(Buffer.from(getToken().split('.')[1],'base64').toString('ascii'))
}