import { useState } from "react";
import { IconLeft, IconRight } from "../../../../assets/icons/Icons";
import SidebarActivarCuenta from "../../../../components/sidebar/SidebarActivarCuenta";
import BeneficiosCard from "./BeneficiosCard";

const Beneficios = ({showLogin, setShowLogin}) => {

    const [showActivarCuenta, setShowActivarCuenta] = useState(false)

    const openActivarCuenta = () => {
        setShowActivarCuenta(true)
    }

    return (
        <>
            <SidebarActivarCuenta
                show={showActivarCuenta}
                close={setShowActivarCuenta}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
            <div className="beneficios" id="beneficios">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="beneficios__title">Conoce todos los beneficios del programa que Cardif Seguros y Coursera tienen para ti</h2>
                            </div>
                        </div>

                        {/* VERSION MOBILE */}
                        <div className="d-block d-sm-none container-fluid beneficios__slider">
                            <div className="row">
                                <div className="col-12">
                                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-interval="false">
                                        <div className="carousel-beneficios carousel-fade">
                                            <div className="carousel-item">
                                                <BeneficiosCard 
                                                    image={"/assets/img/homeout/beneficios/images/img-estudio.png"}
                                                    icon={"/assets/img/homeout/beneficios/icons/ico-estudio.svg"}
                                                    title={"Estudia cuándo y dónde quieras"}
                                                    text={"Inscríbete y estudia a tu ritmo desde tu celular, tablet o computadora."}
                                                />
                                            </div>
                                            <div className="carousel-item">
                                                <BeneficiosCard 
                                                    image={"/assets/img/homeout/beneficios/images/img-cursos.png"}
                                                    icon={"/assets/img/homeout/beneficios/icons/ico-cursos.svg"}
                                                    title={"Accede a más de 10,000 cursos"}
                                                    text={"Desarrolla tus habilidades en temas de innovación como: Machine Learning, Mobile Development, Marketing Digital, Data Science y más."}
                                                />
                                            </div>
                                            <div className="carousel-item active">
                                                <BeneficiosCard 
                                                    image={"/assets/img/homeout/beneficios/images/img-necesidades.png"}
                                                    icon={"/assets/img/homeout/beneficios/icons/ico-necesidades.svg"}
                                                    title={"Especialízate de acuerdo a tus necesidades"}
                                                    text={"Amplía tu conocimiento y cumple tus objetivos profesionales"}
                                                />
                                            </div>
                                            <div className="carousel-item">
                                                <BeneficiosCard 
                                                    image={"/assets/img/homeout/beneficios/images/img-certificados.png"}
                                                    icon={"/assets/img/homeout/beneficios/icons/ico-certificados.svg"}
                                                    title={"Obtén certificados profesionales"}
                                                    text={"En las mejores instituciones educativas y empresariales como UNAM, ITESM, Harvard, Google, IBM, Amazon, entre otras"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container d-block d-sm-none">
                            <div className="row">
                                <div className="col-6 beneficios__text-right">
                                    <button className="beneficios__slide-btn" data-bs-target="#carouselExampleFade" type="button" data-bs-slide="prev">
                                        <IconLeft width={"30px"} height={"30px"}/>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button className="beneficios__slide-btn" data-bs-target="#carouselExampleFade" type="button" data-bs-slide="next">
                                        <IconRight width={"30px"} height={"30px"}/>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>


                        {/* VERSION DESKTOP */}
                        <div className="row justify-content-center d-none d-sm-flex">
                            <BeneficiosCard 
                                image={"/assets/img/homeout/beneficios/images/img-estudio.png"}
                                icon={"/assets/img/homeout/beneficios/icons/ico-estudio.svg"}
                                title={"Estudia cuándo y dónde quieras"}
                                text={"Inscríbete y estudia a tu ritmo desde tu celular, tablet o computadora."}
                            />
                            <BeneficiosCard 
                                image={"/assets/img/homeout/beneficios/images/img-cursos.png"}
                                icon={"/assets/img/homeout/beneficios/icons/ico-cursos.svg"}
                                title={"Accede a más de 10,000 cursos"}
                                text={"Desarrolla tus habilidades en temas de innovación como: Machine Learning, Mobile Development, Marketing Digital, Data Science y más."}
                            />
                            <BeneficiosCard 
                                image={"/assets/img/homeout/beneficios/images/img-necesidades.png"}
                                icon={"/assets/img/homeout/beneficios/icons/ico-necesidades.svg"}
                                title={"Especialízate de acuerdo a tus necesidades"}
                                text={"Amplía tu conocimiento y cumple tus objetivos profesionales"}
                            />
                            <BeneficiosCard 
                                image={"/assets/img/homeout/beneficios/images/img-certificados.png"}
                                icon={"/assets/img/homeout/beneficios/icons/ico-certificados.svg"}
                                title={"Obtén certificados profesionales"}
                                text={"En las mejores instituciones educativas y empresariales como UNAM, ITESM, Harvard, Google, IBM, Amazon, entre otras"}
                            />
                        </div>

                        <div className="row justify-content-center mt-3">
                            <div className="col-12 col-lg-8 col-xl-7 mt-lg-5 mb-lg-5 text-center">
                                <div className="beneficios__footer">
                                    <p className="beneficios__footer__text mb-3 mb-lg-0">Activa tu cuenta y comienza ahora</p>
                                    <button className="btn__azul mb-4" onClick={openActivarCuenta}>UNIRME AHORA</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Beneficios