import React from 'react'
import HeaderUser from './HeaderUser'
import { useState } from 'react';
import SidebarLogin from '../sidebar/SidebarLogin';
import SidebarActivarCuenta from '../sidebar/SidebarActivarCuenta';
import SpinnerCuadrado from '../../modules/helpers/SpinnerCuadrado';

const HeaderMenu = ({globalAutenticado}) => {


    const [showMenu, setShowMenu] = useState(false);


    const onToggleMenu = () => {
        setShowMenu(!showMenu)
    }

    const [showActivarCuenta, setShowActivarCuenta] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [showLogin, setShowLogin] = useState(false)

    const openActivarCuenta = () => {
        setShowMenu(!showMenu)
        setShowActivarCuenta(true)
    }

    const openLogin = () => {
        setShowMenu(!showMenu);
        setShowLogin(true);
    }

    const scrollToElement = (element) => {
        const el = document.getElementById(element)
        const y = el.getBoundingClientRect().top + window.pageYOffset - 65

        window.scrollTo({ top: y, behavior: 'smooth' })
    }

    return (
        <>
        {
            spinner && <SpinnerCuadrado />
        }

        <SidebarLogin
            show={showLogin}
            setShow={setShowLogin}
            setSpinner={setSpinner}
        />

        <SidebarActivarCuenta
            show={showActivarCuenta}
            close={setShowActivarCuenta}
            showLogin={showLogin}
            setShowLogin={setShowLogin}
        />

        <div className="home__opciones d-none d-md-flex">
            {
                (!globalAutenticado)
                ? <>
                    <div onClick={() => scrollToElement('beneficios')}>
                        <div className="header__opcion">
                            Tus beneficios
                        </div>
                    </div>
                    <div onClick={openActivarCuenta}>
                        <div className="header__opcion">
                            Activa tu cuenta
                        </div>
                    </div>

                </>
                : <HeaderUser />
            }
        </div>
        <div className="menu__hambur d-flex d-md-none" onClick={onToggleMenu}>
            <div></div>
            <div></div>
            <div></div>
        </div>

        <div className={`menu__movil d-block d-md-none ${showMenu ? 'menu__movil__show' : ''}`}>
            <div className="menu__movil__overlay">
                <div className="menu__movil__content">
                    <div className="menu__movil__hambur" onClick={onToggleMenu}>
                        X   
                    </div>
                    {
                        (!globalAutenticado)
                        ?
                            <div className="menu__movil__text">
                                <p onClick={() => scrollToElement('beneficios')}>Tus beneficios</p>
                                <p onClick={openActivarCuenta}>Activa tu cuenta</p>
                                <p onClick={openLogin}>Iniciar sesión</p>
                            </div>
                        : <div style={{ paddingLeft: '2rem', paddingTop: '4rem' }} onClick={onToggleMenu}><HeaderUser /></div>
                    }

                </div>
            </div>
        </div>

        </>
    )
}

export default HeaderMenu