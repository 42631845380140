import { Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import TagManager from 'react-gtm-module/dist/TagManager';
import { recovery } from '../../services/serviceVivetuseguro';
import { addLogs } from '../../services/logs_service';

const SidebarPasswordDocumento = ({ errorData, setErrorData, mensaje, setMensaje, recaptchaRef, setSpinner, form, setForm, setFormCode, formCode, setCount, urlApi, codRecaptcha }) => {

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmitDocumento = async (e) => {
        e.preventDefault();

        // onChangeCap(e);
        if (form.identificacion.trim() !== "") {

            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)

            let objUsuario = {
                identificacion: form.identificacion,
                tokenCaptcha: token
            }

            addLogs('login', 'recuperar contraseña usuario', `${objUsuario}`);

            try {
                recovery(objUsuario).then(res => {
                    // console.log({ res })
                    setSpinner(false)
                    if (res.codigo === 0) {

                        setMensaje("")
                        setErrorData(false)
                        setFormCode({ ...formCode, id: res.data, tokenCaptcha: token })

                        setCount(2)
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'recuperarContraseña',
                                category: 'login',
                                action: 'Recuperar contraseña',
                                label: '(not available)',
                            }
                        })
                        return
                    } else if (res.codigo === 1) {
                        if (res.mensaje === 'No se validó el captcha') {
                            setMensaje(res.mensaje)
                        } else {
                            setMensaje('Número celular no válido.')
                        }
                    } else {
                        if (res.mensaje === undefined) {
                            setMensaje(res.Message)
                        }
                        else {
                            setMensaje(res.mensaje)
                        }
                    }
                    setErrorData(true)
                })
            } catch (err) {
                setMensaje(err)
                setErrorData(true)
                setSpinner(false)
            }
        } else {
            setMensaje("Para continuar debes completar todos los campos.")
        }
    }

    return (
        <>
            <div className="body__login">
                <h2>¿Olvidaste tu contraseña?</h2>
                <p>No hay problema. Ingresa tu celular, para recuperar tu contraseña.</p>
                {
                    errorData &&
                    <div className='error__box'>
                        <div className='texto__error'>
                            <p>{mensaje}</p>
                        </div>
                    </div>
                }
                <Form autoComplete="off" onSubmit={(e) => { e.preventDefault()}} className="form__modal" >
                    <div className="row justify-content-center">
                        <div className='col-12'>
                            <input
                                type='text'
                                className='form-control border-input '
                                id='identificacion'
                                name='identificacion'
                                autoComplete="off"
                                placeholder='Celular'
                                value={form.identificacion}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ textAlign: 'center' }} className='col-12 mt-3 pt-5 mb-4'>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={codRecaptcha}
                            />
                            <button className="btn__azul" onClick={handleSubmitDocumento}>ENVIAR CÓDIGO</button>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default SidebarPasswordDocumento