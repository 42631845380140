const RequisitosCard = ({Icon, title, text,IconWave, fill, click}) => {
    return (
        <>
            <div onClick={click} className="col-12 col-md-4 col-lg-3 mb-3 cadastre__container_card" style={{cursor: click ? 'pointer': 'default'}}>
                <div className="cadastre__card">
                    <div className="cadastre__card__title"> 
                        <IconWave fillColor={fill}/>
                        <Icon />
                        <h4 className="cadastre__card__title__text ms-3" dangerouslySetInnerHTML={{__html: title}}></h4>
                    </div>
                    <div className="cadastre__card__text">
                        <p dangerouslySetInnerHTML={{__html: text}}></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequisitosCard