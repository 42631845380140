import { createRef, useState } from "react"
import Offcanvas from 'react-bootstrap/Offcanvas';
import ButtonClose from "../../modules/homeout/principal/components/modal/ButtonClose";
import SidebarPasswordCodigo from "./SidebarPasswordCodigo";
import SidebarPasswordDocumento from "./SidebarPasswordDocumento";
import SidebarPasswordNewPass from "./SidebarPasswordNewPass";

const SidebarPassword = ({urlApi, codRecaptcha, show, setShow, setShowModalSuccess, setSpinner}) => {

    const handleClose = () => {
        setShow(false);
        setCount(1);
    }
    const [errorData, setErrorData] = useState(false)
    const [count, setCount] = useState(1)
    const [mensaje, setMensaje] = useState("");
    const [formCode, setFormCode] = useState({
        id: "",
        code: "",
        tokenCaptcha: ""
    })

    const [form, setForm] = useState({
        identificacion: "",
        tokenCaptcha: ""
    })

    // RECAPTCHA
    const recaptchaRef = createRef()

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <ButtonClose close={handleClose} />
                <Offcanvas.Body>
                    <div className="sticky__btn__sidebar_up d-lg-block d-none">
                        <button className="btn__sidebar" type="button" onClick={handleClose} >
                            <b>INGRESA A TU<br/>ECOSISTEMA AQUÍ</b>
                            <i className="fa-solid fa-angle-down flecha__sticky__btn"></i>
                        </button>
                    </div>
                    {
                        count === 1 &&
                        <SidebarPasswordDocumento
                            errorData={errorData}
                            mensaje={mensaje}
                            recaptchaRef={recaptchaRef}
                            setMensaje={setMensaje}
                            setSpinner={setSpinner}
                            setErrorData={setErrorData}
                            setFormCode={setFormCode}
                            formCode={formCode}
                            setCount={setCount}
                            form={form}
                            setForm={setForm}
                            urlApi={urlApi}
                            codRecaptcha={codRecaptcha}
                        />
                    }
                    {
                        count === 2 &&
                        <SidebarPasswordCodigo
                            errorData={errorData}
                            mensaje={mensaje}
                            formCode={formCode}
                            recaptchaRef={recaptchaRef}
                            setMensaje={setMensaje}
                            setSpinner={setSpinner}
                            form={form}
                            setErrorData={setErrorData}
                            setCount={setCount}
                            setFormCode={setFormCode}
                            urlApi={urlApi}
                            codRecaptcha={codRecaptcha}
                            close={handleClose}
                        />
                    }
                    {
                        count === 3 &&
                        <SidebarPasswordNewPass
                            errorData={errorData}
                            mensaje={mensaje}
                            recaptchaRef={recaptchaRef}
                            setMensaje={setMensaje}
                            setSpinner={setSpinner}
                            setErrorData={setErrorData}
                            formCode={formCode}
                            setCount={setCount}
                            urlApi={urlApi}
                            codRecaptcha={codRecaptcha}
                            handleClose={handleClose}
                            setShowModalSuccess={setShowModalSuccess}
                        />
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default SidebarPassword