import { Suspense } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import FallbackCards from "../modules/helpers/FallbackCards"
import HomeOutScreen from "../modules/homeout/principal/screens/HomeOutScreen"
import ScrollToTop from "./ScrollToTop"

const RouterHomeOut = () => {
    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<HomeOutScreen />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Suspense>
        </>
    )
}

export default RouterHomeOut