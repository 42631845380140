import { URL_BACKEND_AUTHS } from "../environment/variables"

export const postLogin = async (obj) => {

    try{
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/IniciarSession`, {
            mode: 'cors',
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        })
        const json = await response.json()
        return json
    }catch(err){
        console.log(err)
    }
}

export const getSession = async (token) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/obtenerSession`, {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const postRegister = async (objUsuario) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/RegistrarUser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })

        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const postUpdate = async (objUsuario) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/login/updateuser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const recovery = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/RecuperaContrasena`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const sendCode = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/EnvioCodigo`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const changePassword = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/CambiarContrasena`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}