import { createRef, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ReCAPTCHA from 'react-google-recaptcha';
import sha256 from 'sha256';
import { encodeBase64, encryptDecrypt, getIdUser } from '../../modules/helpers/validations';
//import { InputIconEyeActive, InputIconEyeInactive } from '../../assets/icons/Icons';
import ButtonClose from '../../modules/homeout/principal/components/modal/ButtonClose';
import SidebarActivarCuenta from './SidebarActivarCuenta';
import { getSession, postLogin } from '../../services/serviceVivetuseguro';
import * as CryptoJS from 'crypto-js'
import TagManager from 'react-gtm-module';
import SidebarPassword from './SidebarPassword';
import ModalTerminos from '../../modules/homeout/principal/components/modal/ModalTerminos';
import { CLI_PUB, COD_RECAPTCHA_V3 } from '../../environment/variables';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import { addLogs } from '../../services/logs_service';

const SidebarLogin = ({show, setShow, setShowModalSuccess, setSpinner}) => {

    const handleClose = () => setShow(false);
    const [typeField, setTypeField] = useState(false);
    const [typeInput, setTypeInput] = useState('password');
    const [showModalActivar, setShowModalActivar] = useState(false)
    const [showModalPassword, setShowModalPassword] = useState(false)
    const [mensaje, setMensaje] = useState('')
    const [showTyc, setShowTyc] = useState(false)

    const {globalIniciarSesion} = useContext(AuthContext)

    // RECAPTCHA
    const recaptchaRef = createRef();

    let navigate = useNavigate();

    const [form, setForm] = useState({
        user: '',
        password: ''
    })

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }


    const handleTypeField = (type)=> {
        setTypeField(type);
        setTypeInput((type) ? 'text' : 'password');
    }

    const getTyC = () => {
        setShowTyc(true)
    }

    const handleSubmit = async () => {
        if (form.user.trim() !== "" && form.password.trim() !== "") {
            const token = await recaptchaRef.current.executeAsync();

            recaptchaRef.current.reset()
            if (!token) {
                // console.log('err captcha')
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)
            let objUsuario = {
                usuario: encodeBase64(form.user),
                password: encodeBase64(encryptDecrypt(sha256(form.password),
                    token.substring(token.length - 10, token.length)
                )),
                sitioPassword: "9B9CB6631B27AA1DF47DB351B497004810015B09",
                sitioUsuario: "CARDIF",
                deviceInfo: '',
                tokenCaptcha: token,
            }

            try {
                postLogin(objUsuario).then(rpta => {
                    console.log({rpta})
                    if (rpta.codigo === 0) {
                        localStorage.setItem('token', CryptoJS.AES.encrypt(rpta.data.token, CLI_PUB));
                        getSession(rpta.data.token).then(data => {
                            let res = { data }.data
                            let perfilesActivos = res.perfiles.$values

                            if(perfilesActivos.length === 0) {
                                setMensaje("Aun no cuentas con algún seguro activo.")
                                setSpinner(false)
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'login',
                                        'category': 'login',
                                        'action': 'Intento de logeo',
                                        'label': 'Error',
                                    }
                                })
                                return
                            }

                            localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(res), CLI_PUB));
                            setSpinner(false)

                            //Una vez validado que el usuario tenga el Seguro de Proteccion Financiera, se procede a ir al sitio de vivetuseguro pasando el token como parametro.
                            /*let urlVivetuseguro = infoApi[0].urlFront + rpta.data.token
                            window.location.href = urlVivetuseguro*/

                            setMensaje('')
                            addLogs("Iniciar Sesión", "Inicio de sesión exitoso", JSON.stringify(objUsuario));
                            let idUser = getIdUser()
                            globalIniciarSesion({
                                globalDataUser: {
                                    globalUsuId: idUser,
                                    globalUsuName: res.nombres,
                                    globalUsuSurnameMat: res.apellidoMaterno,
                                    globalUsuSurname: res.apellidoPaterno,
                                    globalUsuCellphone: res.numeroCelular,
                                    globalUsuEmail: res.correoElectronico,
                                    globalUsuTipoDoc: res.dicTipoDocumento,
                                    globalDocIdentidad: res.identificacion,
                                    globalUsuRole: "",
                                    globalInvitado: res.nroinvitado > 0,
                                    globalNroInvitados: res.nroinvitado,
                                    globalOrigenIdSocio: res.OrigenIdSocio,
                                },
                                globalToken: CryptoJS.AES.encrypt(rpta.data.token, CLI_PUB),
                                globalTokenUser: CryptoJS.AES.encrypt(JSON.stringify(res), CLI_PUB),
                                globalAutenticado: true,
                                globalCargando: false,
                                globalStatusEncuesta: res.encuestaNeo === 1
                            })
                            setMensaje('')
                            // TAG MANAGER INICIO:
                            TagManager.dataLayer({
                                dataLayer: {
                                    evento: 'inicioSesion',
                                    userId: res.identificacion,
                                    userDoc: res.dicTipoDocumento === 8 ? 'RFC' : 'Pasaporte'
                                }
                            })

                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'login',
                                    category: 'login',
                                    action: 'Intento de logeo',
                                    label: 'Satisfactorio',
                                }
                            })
                            handleClose();
                            navigate("/ecosistema", {
                                state: {
                                    loggedIn: true
                                }
                            })

                        })

                    } else {
                        setMensaje(rpta.mensaje)
                        setSpinner(false)
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'login',
                                'category': 'login',
                                'action': 'Intento de logeo',
                                'label': 'Error',
                            }
                        })
                    }
                })
            } catch (err) {
                TagManager.dataLayer({
                    dataLayer: {
                        'event': 'login',
                        'category': 'login',
                        'action': 'Intento de logeo',
                        'label': 'Error',
                    }
                })
                setMensaje('Hubo un problema en la autenticación.')
                setSpinner(false)
            }
        } else {
            setMensaje('Para continuar debes completar todos los campos.')
        }
    }

    const openRegister = () => {
        handleClose()
        setMensaje('')
        setShowModalActivar(true)
    }

    const openModalPassword = () => {
        handleClose()
        setShowModalPassword(true)
    }

    return (
        <>
            <ModalTerminos
                show={showTyc}
                close={setShowTyc}
            />

            <SidebarActivarCuenta
                show={showModalActivar}
                close={setShowModalActivar}
                showLogin={show}
                setShowLogin={setShow}
            />
            <SidebarPassword
                codRecaptcha={COD_RECAPTCHA_V3}
                show={showModalPassword}
                setShow={setShowModalPassword}
                setShowModalSuccess={setShowModalSuccess}
                setSpinner={setSpinner}
            />

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <ButtonClose close={handleClose} />
                <Offcanvas.Body>
                    <div className="sticky__btn__sidebar_up d-lg-block d-none">
                        <button className="btn__sidebar" type="button" onClick={handleClose} >
                            <b>INGRESA A TU<br/>ECOSISTEMA AQUÍ</b>
                            <i className="fa-solid fa-angle-down flecha__sticky__btn"></i>
                        </button>
                    </div>
                    <div className="body__login">
                        <h2>Acceder a tu cuenta</h2>
                        <p>Complete la información a <div className='d-block d-lg-none'></div>continuación para ingresar:</p>
                        {
                            mensaje !== '' &&
                            <div className='error__box'>
                                <div className='texto__error'>
                                    <p>{mensaje}</p>
                                </div>
                            </div>
                        }
                        <Form autoComplete="off" onSubmit={(e) => { e.preventDefault()}} className="form__modal" >
                            <div className="row justify-content-center">
                                <div className='col-12'>
                                    <input
                                        type='text'
                                        className='form-control border-input '
                                        id='user'
                                        name='user'
                                        placeholder='E-mail'
                                        autoComplete="off"
                                        value={form.user}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-12 login__pass'>
                                    <input
                                        type={typeInput}
                                        className='form-control border-input '
                                        id='password'
                                        name='password'
                                        placeholder='Contraseña'
                                        autoComplete="off"
                                        value={form.password}
                                        onChange={handleChange}
                                    />
                                    {
                                        typeField
                                        ? <i onClick={() => handleTypeField(!typeField)} className="fa-solid fa-eye pass__icon"></i>
                                        : <i onClick={() => handleTypeField(!typeField)} className="fa-solid fa-eye-slash pass__icon"></i>
                                    }
                                </div>
                                <div className='col-12 recuperar__pass'onClick={openModalPassword}>
                                    <p style={{fontStyle:'italic'}}>Olvidé mi contraseña</p>
                                </div>
                                <div style={{ textAlign: 'center' }} className='col-12 mt-3 pt-5 mb-4'>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey={COD_RECAPTCHA_V3}
                                    />
                                    <div className='d-block d-lg-none'>
                                        <button className="btn__azul" style={{ padding: '1rem 7rem' }} onClick={handleSubmit}>ENTRAR</button>
                                    </div>
                                    <div className='d-none d-lg-block'>
                                        <button className="btn__azul" onClick={handleSubmit}>ENTRAR</button>
                                    </div>
                                </div>
                            </div>
                            <hr className='modal__login__underline mt-5'></hr>
                            <div className='div__activar-cuenta'>
                                <h2>¿Aún no has activado tu cuenta?</h2>
                                <div style={{ textAlign: 'center' }} className='col-12 d-block d-lg-none'>
                                    <button className="btn__azul" style={{ padding: '1rem 5rem' }} onClick={openRegister}>ACTIVAR CUENTA</button>
                                </div>
                                <div style={{ textAlign: 'center' }} className='col-12 d-none d-lg-block'>
                                    <button className="btn__azul" onClick={openRegister}>ACTIVAR CUENTA</button>
                                </div>
                                <div className='col-12 mt-3'>    
                                    <p className='modal__login__footer text-center mt-5' onClick={getTyC}>Términos y condiciones</p>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default SidebarLogin