import { Modal } from "react-bootstrap"
import Swal from'sweetalert2'
import ButtonClose from "./ButtonClose"
import { getToken } from "../../../../helpers/validations"
import { useEffect, useState } from "react"
import Select from 'react-select'
import { getDiccionario, saveCourseraData } from "../../../../../services/coursera"
import "./modal.css"
import { addLogs } from "../../../../../services/logs_service"

const ModalEncuestaCoursera = (props) => {

    const handleClose = () => {
        props.close(false)
    }

    const limpiarEncuesta = () => {
        var radiobuttonsOcupacion = document.getElementsByName('ocupacion')
        for (var i = 0; i < radiobuttonsOcupacion.length; i++) {
            radiobuttonsOcupacion[i].checked = false
        }

        setInfoCuestionario({ ...infoCuestionario, 'ocupacion': null })

        var checkboxTemas = document.getElementsByName('temasInteres')
        for(var j = 0; j < checkboxTemas.length; j++) {
            checkboxTemas[j].checked = false
        }

    }

    const validaInfo = () => {
        if (infoCuestionario.ocupacion !== null && infoCuestionario.temasInteres != null) {
            if (infoCuestionario.ocupacion !== 'Empleado') {
                return true
            }
            else {
                if (infoCuestionario.profesion !== '') {
                    return true
                }
            }
        }
        return false
    }

    const enviarEncuesta = () => {
        var info = JSON.stringify(infoCuestionario)
        console.log(info);
        if (!validaInfo()) {
            Swal.fire("Error","Debes rellenar todos los campos",'error');
            return
        }else{
            addLogs('Envio_Encuesta_Coursera', 'Usuario envia encuesta de coursera exitosamente', '')
            saveCourseraData(getToken(), infoCuestionario)
        }
        handleClose()
    }

    const [infoCuestionario, setInfoCuestionario] = useState({
        ocupacion: '',
        profesion: '',
        temasInteres: null
    })

    const onChangeOcupacion = (e) => {
        setInfoCuestionario({ ...infoCuestionario, [e.target.name]: e.target.value })    
    }

    const onChangeOcupacionMobile = (e, name) => {
        setInfoCuestionario({ ...infoCuestionario, [name]: e.value })
    }

    const onChangeInfoSelect = (e, name) => {
        setInfoCuestionario({ ...infoCuestionario, [name]: e.label })
    }

    const onChangeCheckTemas = (e) => {
        var r = ""
        var inputCheckboxes = document.getElementsByClassName('checkTemas')
        for (var i=0; inputCheckboxes[i]; i++) {
            if (inputCheckboxes[i].checked) {
                if (i === inputCheckboxes.length - 1)
                    r = r + inputCheckboxes[i].value
                else r = r + inputCheckboxes[i].value + ', '
            }
        }
        setInfoCuestionario({ ...infoCuestionario, [e.target.name]: r})
    }

    const [profesiones, setProfesiones ] = useState([])
    const ocupaciones = [
        {value: '', label: 'Selecciona'},
        {value: 'Empleado', label: 'Soy empleado'},
        {value: 'Estudiante', label: 'Soy estudiante'},
        {value: 'Emprendedor', label: 'Soy emprendedor'},
        {value: 'Otro', label: 'Otro'}
    ]

    const obtenerProfesiones = () => {
        getDiccionario(2).then(res => {
            let newProf = [{ value: '', label: 'Selecciona' }]
            res.data.$values.forEach(element => {
                let array = { value: element.id, label: element.text }
                newProf.push(array)
            })
            setProfesiones(newProf)
        })
    }

    useEffect(() => {
        obtenerProfesiones()
    }, [])

    return (
        <>
            <Modal show={props.show} onHide={handleClose} size="lg" backdrop="static" centered>
                <Modal.Body>
                    <ButtonClose close={handleClose} />
                    <div className="encuesta__coursera">
                        <h1>Queremos conocerte</h1>
                        <p>Ayúdanos a conocer tus intereses, por favor toma un minuto para responder las siguientes preguntas:</p>
                    </div>
                    <div className="preguntas__modal">
                        <h3 className='titulo_seccion' style={{ fontFamily: 'GT-America-Regular', paddingTop: '50px', fontSize: '1.6rem'}}>1. Selecciona tu ocupación</h3><br/>
                        {/* <select defaultValue={""} onChange={(e) => { onChangeOcupacion(e) }}>
                            <opcion value="" disabled>Selecciona</opcion>
                            <opcion value="Empleado">Soy empleado</opcion>
                            <opcion value="Estudiante">Soy estudiante</opcion>
                            <opcion value="Emprendedor">Soy emprendedor</opcion>
                            <opcion value="Otro">Otro</opcion>
                        </select> */}

                        <div className="d-block d-lg-none">
                            <Select
                                defaultValue={ocupaciones[0]}
                                onChange={(e) => {onChangeOcupacionMobile(e, 'ocupacion')}}
                                options={ocupaciones}
                                id='ocupacion'
                            />
                        </div>
                        
                        <div className='custom-radio d-none d-lg-block' style={{ fontSize: "1.6rem" }}>
                            <input className='cuestionario__radio-button' type="radio" name="ocupacion" value="Empleado" style={{ verticalAlign: 'middle'}} onChange={(e) => { onChangeOcupacion(e) }}/>
                            <label style={{ paddingRight: '3rem' }}>Soy empleado</label>
                            <div className="breakLine-mobile"></div>
                            <input className='cuestionario__radio-button' type="radio" name="ocupacion" value="Estudiante" style={{ verticalAlign: 'middle'}} onChange={(e) => { onChangeOcupacion(e) }} />
                            <label style={{ paddingRight: '3rem' }}>Soy estudiante</label>
                            <div className="breakLine-mobile"></div>
                            <input className='cuestionario__radio-button' type="radio" name="ocupacion" value="Emprendedor" style={{ verticalAlign: 'middle'}} onChange={(e) => { onChangeOcupacion(e) }} />
                            <label style={{ paddingRight: '3rem' }}>Soy emprendedor</label>
                            <div className="breakLine-mobile"></div>
                            <input className='cuestionario__radio-button' type="radio" name="ocupacion" value="Otro" style={{ verticalAlign: 'middle'}} onChange={(e) => { onChangeOcupacion(e) }} />
                            <label>Otro</label>
                        </div>
                        {
                            (infoCuestionario.ocupacion === 'Empleado') &&
                            <>
                                <div name="divProfesion">
                                    <h3 className='titulo_seccion' style={{ fontFamily: 'GT-America-Regular', paddingTop: '50px'}}>¿Cuál es tu profesión?</h3><br/>
                                    <Select
                                        defaultValue={profesiones[0]}
                                        onChange={(e) => {onChangeInfoSelect(e, 'profesion')}}
                                        options={profesiones}
                                        id='profesion'
                                    />
                                </div>
                            </>
                        }
                        <h3 className='titulo_seccion' style={{ fontFamily: 'GT-America-Regular', paddingTop: '50px', fontSize: '1.6rem'}}>2. Selecciona uno o más temas de interés</h3><br/>
                        <div className='custom-checkbox' style={{ "fontSize": "1.6rem", "padding": "1.5rem 0" }}>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Aprendizaje de Idiomas" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Aprendizaje de Idiomas</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Desarrollo Personal y Ciencias de la Vida" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Desarrollo Personal y Ciencias de la Vida</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Artes y Humanidades" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Artes y Humanidades</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Física, Ingeniería y Lógica Matemática" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Física, Ingeniería y Lógica Matemática</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Big Data y Ciencia de Datos" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Big Data y Ciencia de Datos</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Inteligencia Artificial y Tecnologías Emergentes" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Inteligencia Artificial y Tecnologías Emergentes</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Ciencias Sociales y Habilidades Humanas" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Ciencias Sociales y Habilidades Humanas</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Negocios y Habilidades de Negocio" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Negocios y Habilidades de Negocio</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Computación en la Nube (Cloud Computing)" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Computación en la Nube (Cloud Computing)</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Tecnologías de la Información y Ciencias de la Computación" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Tecnologías de la Información y Ciencias de la Computación</label><br/>
                            <input className='cuestionario__checkbox checkTemas' type="checkbox" name="temasInteres" value="Desarrollo de Aplicaciones y Páginas Web" onChange={(e) => { onChangeCheckTemas(e) }} />
                            <label style={{ marginRight: "30px" }}>Desarrollo de Aplicaciones y Páginas Web</label><br/>
                        </div>
                        <div className="card__btns__encuesta d-block d-lg-none">
                            <button className="btn__enviar__encuesta" style={{ borderRadius: '7px', fontSize: '12px' }} onClick={enviarEncuesta} >ENVIAR ENCUESTA</button>
                            <button className="btn__limpiar__encuesta" style={{ borderRadius: '7px', fontSize: '12px' }} onClick={limpiarEncuesta} >LIMPIAR ENCUESTA</button>
                        </div>
                        <div className="card__btns__encuesta d-none d-lg-flex">
                            <button className="btn__enviar__encuesta" style={{ borderRadius: '7px', fontSize: '12px' }} onClick={enviarEncuesta} >ENVIAR ENCUESTA</button>
                            <button className="btn__limpiar__encuesta" style={{ borderRadius: '7px', fontSize: '12px' }} onClick={limpiarEncuesta} >LIMPIAR ENCUESTA</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalEncuestaCoursera