import * as CryptoJS from "crypto-js";
import { getToken, getToken2, isactive } from "./validations";
import { CLI_PUB, user as us, password } from "../../environment/variables";
import { refreshToken } from "../../services/token";

export function refreshTokenSession(){

    try{
        let token = getToken2()
        let user = JSON.parse(token['Usuario']);
        
        let form ={ 
            usuario: user['identificacion'], 
            password: user['clave'], 
            sitioUsuario: `${us}`, 
            sitioPassword: `${password}` }

            setInterval(() => {
                if (isactive()) {
                    refreshToken(getToken(),form).then(res=>{
                        localStorage.setItem('token', CryptoJS.AES.encrypt(res.token, CLI_PUB));
                    })
                }
                
              }, 60000);

            refreshToken(getToken(),form).then(res=>{
                localStorage.setItem('token', CryptoJS.AES.encrypt(res.token, CLI_PUB));
            })

    }catch(err){

    }

}