import { Route, Routes } from "react-router-dom"
import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"
//import SidebarLogin from "../components/sidebar/SidebarLogin"
import RouterHomeIn from "./RouterHomeIn"
import RouterHomeOut from "./RouterHomeOut"
import RutaPrivada from "../modules/helpers/RutaPrivada"
import { useContext, useEffect } from "react"
import AuthContext from "../context/auth/authContext"
import ModalExpiredSession from "../modules/homeout/principal/components/modal/ModalExpiredSession"
import { useState } from "react"

const RouterMain = () => {

    const {globalVentanaCierre} = useContext(AuthContext)
    const [showModalExpiredSession, setShowModalExpiredSession] = useState(false)

    useEffect(() => {
        setShowModalExpiredSession(true)
    }, [])

    return (
        <>
            <Header />

            {globalVentanaCierre && <ModalExpiredSession show={showModalExpiredSession} close={setShowModalExpiredSession} />}

            <Routes>
                <Route path="/ecosistema/*" element={<RutaPrivada><RouterHomeIn /></RutaPrivada>} />
                <Route path="/*" element={<RouterHomeOut />} />
            </Routes>

            <Footer />
        </>
    )
}

export default RouterMain