import { useState } from 'react';
import SidebarActivarCuenta from '../../../../components/sidebar/SidebarActivarCuenta';
import '../styles/HeaderHomeOut.css'

const HeadHomeOut = ({showLogin, setShowLogin}) => {

    const [showActivarCuenta, setShowActivarCuenta] = useState(false)

    /*const downloadCondicionesGenerales = () => {
        const downloadLink = document.createElement("a");
        downloadLink.href = props.pdfCondiciones
        downloadLink.download = "Condiciones Generales.pdf"
        downloadLink.click()
    }*/

    const openActivarCuenta = () => {
        setShowActivarCuenta(true)
    }

    return (
        <>
            <SidebarActivarCuenta
                show={showActivarCuenta}
                close={setShowActivarCuenta}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
            <div className="container-fluid banner pe-0 ps-0 d-block d-lg-none">
                {/* <div className="container-xxl pe-0 ps-0"> */}
                    <div className="row me-0">
                        <div className="col-12 col-md-6 pt-lg-4 pe-0">
                            <div>
                                <img src='/assets/img/homeout/img-banner-homeoutheader-meli.webp' className="banner__image d-block d-md-none" alt='' />
                            </div>
                            <div className="banner__text__center">
                                <h5 className="banner__title"><b>DISFRUTA DE LOS BENEFICIOS QUE TU SEGURO TIENE PARA TI</b></h5>
                                <p className="banner__text pe-3 mt-3 pb-3">Accede a Coursera completamente gratis, la plataforma #1 de aprendizaje en línea</p>
                                <button className="banner__button" onClick={openActivarCuenta}>ACTIVA TU CUENTA</button>
                            </div>

                            <div className="banner__footer align-items-center d-flex d-md-none">
                                <div className="cps-5 banner__footer__text pe-3">
                                    <p>Llama sin costo <span><b><a href="tel:8005227343" style={{ color: '#ffffff' }}>800 522 7343</a></b></span></p>
                                    <p>Horarios de atención:</p>
                                    <p>Lunes a vie de 08:00 a 21:00 hrs.<br/>sáb de 09:00 a 18:00 hrs.</p>
                                </div>
                                <div className="text-center ps-3">
                                    <p><b><a href="mailto:sac_mx@cardif.com.mx" style={{ color: '#ffffff' }}>sac_mx@cardif.com.mx</a></b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block pe-lg-0">
                            <img src='/assets/img/homeout/img-banner-homeoutheader-meli.webp' className="banner__image" alt='' />
                        </div>
                    </div>

                    <div className="row banner__footer d-none d-md-flex ms-0 me-0">
                        <div className="col-12">
                            <div className="row align-items-center">
                                <div className="col-9 ps-5 banner__footer__text">
                                    <p className="">Llama sin costo <span className="ps-3 pe-3"><b><a href="tel:8005227343" style={{ color: '#ffffff' }}>800 522 7343</a></b></span></p>
                                    <p className="pe-3">Horarios de atención:<br/> Lunes a vie de 08:00 a 21:00 hrs.<br/>sáb de 09:00 a 18:00 hrs.</p>
                                    <p></p>
                                    <p></p>
                                </div>
                                <div className="col-3 text-center">
                                    <p><a href="mailto:sac_mx@cardif.com.mx" style={{ color: '#ffffff' }}>sac_mx@cardif.com.mx</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>
            <div className='home p-0 d-none d-lg-block'> 
                <div className='container-cookie bannerInicial'>
                    <div className='row bannerH'>
                        <div className='col-12 col-md-6 col-back'>
                            <div className='home__text-container'>
                                <div className='home__title mb-5'>
                                    <h2>DISFRUTA DE LOS BENEFICIOS QUE TU SEGURO TIENE PARA TI</h2>
                                </div>

                                <div className='home__text mb-5'>
                                    <p className='mb-0 pb-5'>Accede a Coursera completamente gratis, la plataforma #1 de aprendizaje en línea</p>
                                </div>
                                <button className='btn__azul btn__head__homeout mt-5' onClick={openActivarCuenta}>ACTIVA TU CUENTA</button>
                            </div>
                        </div>

                        <div className='col-12 col-md-6 col-image p-0'>
                            <div className='home__image-container'>
                                <img src='/assets/img/homeout/img-banner-homeoutheader-meli.webp' className="home__image_desks" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='head__footer d-none d-lg-grid'>
                <p> Llama sin costo </p> 
                <p><b><a href="tel:8005227343" style={{ color: '#ffffff' }}>800 522 7343</a></b></p>
                <p>Horarios de atención: Lunes a viernes de 08:00 a 21:00 hrs. y sábados de 09:00 a 18:00 hrs.</p>
                <div className='separacion'></div>
                <p><b><a href="mailto:sac_mx@cardif.com.mx" style={{ color: '#ffffff' }}>sac_mx@cardif.com.mx</a></b></p>
            </div>
        </>
    )
}

export default HeadHomeOut