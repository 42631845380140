import { Modal } from "react-bootstrap"
import { programasCoursera } from "../../../../helpers/data"
import { getCoursera } from "../../../../../services/coursera"
import { getToken } from "../../../../helpers/validations"
import Swal from "sweetalert2"
import { addLogs } from "../../../../../services/logs_service"
import { useState } from "react"
import { useEffect } from "react"

const ModalCoursera = ({show, close, setSpinner, OrigenIdSocio}) => {

    const handleClose = () => {
        close(false)
    }

    const [linkCoursera, setLinkCoursera] = useState(null)
    const [refreshCoursera, setRefreshCoursera] = useState(true)

    const gotoCoursera = async() => {
        handleClose()
        addLogs("coursera", "entro a link externo coursera")
        setRefreshCoursera(true)
    }

    setInterval(() => {
        setRefreshCoursera(true)
    }, 60000)

    useEffect(() => {
        try {
            if (!refreshCoursera) return
            let idProgramaCoursera = 0
            if (OrigenIdSocio === "36") {
                idProgramaCoursera = 2
            }
            else {
                idProgramaCoursera = 1
            }
            getCoursera(getToken(), programasCoursera.find(pc => pc.id === idProgramaCoursera)).then(([nuevaRuta, status]) => {
                if (nuevaRuta?.includes('/')) {
                    setLinkCoursera(nuevaRuta)
                    setRefreshCoursera(false)
                    return
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Inconvenientes en la conexión',
                    text: 'Volver a intentar en unos instantes.'
                })
            })
        } catch (error) {
            console.log(error)
        }
    }, [refreshCoursera])

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="modal__coursera">
                        <h3>Acceder a coursera</h3>
                        <p>Serás redirigido a una página externa, si deseas continuar haz clic en "llévame a Coursera", de lo contrario haz clic en "volver" para continuar en la página actual.</p>

                        <div className="div__botones__coursera">
                            <a href={linkCoursera} rel="noopener noreferrer" className={ linkCoursera ? "btn__coursera__go" : "btn__coursera__go__disabled"} onClick={gotoCoursera} target="_blank">LLÉVAME A COURSERA</a>
                            <button className="btn__coursera__volver" onClick={handleClose}>VOLVER</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalCoursera