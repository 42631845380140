import { useState } from "react"
import { IconActivarCuenta, IconSeguro, Wave } from "../../../../assets/icons/Icons"
import SidebarActivarCuenta from "../../../../components/sidebar/SidebarActivarCuenta"
import RequisitosCard from "./RequisitosCard"

const Requisitos = ({showLogin, setShowLogin}) => {

    const [showActivarCuenta, setShowActivarCuenta] = useState(false)

    const openActivarCuenta = () => {
        setShowActivarCuenta(true)
    }

    return (
        <>

            <SidebarActivarCuenta
                show={showActivarCuenta}
                close={setShowActivarCuenta}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />

            <div className="container-fluid cadastre mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="cadastre__title">Qué necesito para disfrutar de mis beneficios</h4>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <RequisitosCard 
                            Icon={IconSeguro}
                            title={"Seguro vigente"}
                            text={"Para tener acceso exclusivo y gratuito al beneficio de Coursera, tu seguro debe estar vigente.​"}
                            IconWave={Wave}
                            fill="#2D3277"
                        />
                        <RequisitosCard 
                            Icon={IconActivarCuenta}
                            title={"Activa tu cuenta"}
                            text={"<u className='cadastre__link'>Regístrate aquí y accede a los cursos en línea</u>"}
                            IconWave={Wave}
                            fill="#2D3277"
                            click={openActivarCuenta}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Requisitos