import { createRef, useContext, useState } from "react";
import { Form } from "react-bootstrap"
//import { useNavigate } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ReCAPTCHA from "react-google-recaptcha";
//import useValidator from "../../hooks/useValidator";
import ButtonClose from "../../modules/homeout/principal/components/modal/ButtonClose";
import TagManager from 'react-gtm-module';
import SpinnerCuadrado from "../../modules/helpers/SpinnerCuadrado";
import { getSession, postLogin, postRegister, postUpdate } from "../../services/serviceVivetuseguro";
import { encodeBase64, encryptDecrypt, getIdUser } from "../../modules/helpers/validations";
import sha256 from "sha256";
import * as CryptoJS from "crypto-js";
import { CLI_PUB, COD_RECAPTCHA_V3 } from "../../environment/variables";
import AuthContext from "../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { addLogs } from "../../services/logs_service";
//import SidebarLogin from "./SidebarLogin";

const SidebarActivarCuenta = (props) => {

    const handleClose = () => props.close(false)

    //const navigate = useNavigate()

    //const [mostrarLogin, setMostrarLogin] = useState(false)

    // RECAPTCHA
    const recaptchaRef = createRef();
    const [loading, setLoading] = useState(false);
    const [mensaje, setMensaje] = useState('')
    const {globalIniciarSesion} = useContext(AuthContext)

    let navigate = useNavigate()

    const [form, setForm] = useState({
        email: '',
        celular: '',
        tipoIdentificacion: '7',
        password: '',
        confirm_password: ''
    })

    //const { formValidador } = useValidator(form.password)

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const gotoLogin = () => {
        handleClose()
        props.setShowLogin(true)
    }

    const handleSubmit = async () => {
        if (form.password === '') {
            setMensaje('Ingresa una contraseña');
            return;
        }

        if (form.confirm_password !== form.password) {
            setMensaje('Las contraseñas no coinciden.')
            return
        }

        if (form.email !== "" && form.celular !== "") {
            // Genero un token y en caso de error limpio la variable para un siguiente intento
            let token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            let tokenUpdate = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            let tokenLogin = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()

            setLoading(true)

            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            if (document.getElementById("checkboxCondiciones").checked === false) {
                setMensaje('Debe aceptar las condiciones de uso.')
                setLoading(false)
                return
            }

            let datos = {
                identificacion: form.email,
                email: form.email,
                celular: form.celular,
                tipoIdentificacion: parseInt(form.tipoIdentificacion),
                tokenCaptcha: token,
            }

            try {
                postRegister(datos).then(rpta => {
                    if (rpta.Codigo === 1 || rpta.Codigo === 10) {
                        setMensaje(rpta.Mensaje);
                        setLoading(false)
                        // setMensaje('Ya existe un usuario con este documento.');
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'formRegistro',
                                category: 'Etapas de Registro',
                                action: 'Paso 1',
                                label: rpta.Mensaje,
                            }
                        })

                    } else if (rpta.Codigo === 2) {
                        // setMensaje(rpta.Mensaje);
                        setMensaje('Ya existe un usuario con este documento, correo y/o celular.');
                        setLoading(false)
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'formRegistro',
                                category: 'Etapas de Registro',
                                action: 'Paso 1',
                                label: 'Ya existe un usuario con este documento.',
                            }
                        })
                    } else if (rpta.Codigo === 3) {
                        // setMensaje(rpta.Mensaje);
                        setMensaje('Producto contratado inactivo');
                        setLoading(false)
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'formRegistro',
                                category: 'Etapas de Registro',
                                action: 'Paso 1',
                                label: 'Producto contratado inactivo',
                            }
                        })

                    } else if (rpta.Codigo === 4) {
                        setMensaje('Información ingresada no corresponde a la registrada. Inténtelo de nuevo.')
                        setLoading(false)
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'formRegistro',
                                category: 'Etapas de Registro',
                                action: 'Paso 1',
                                label: 'Información ingresada no corresponde a la registrada. Inténtelo de nuevo.',
                            }
                        })

                    } else if (rpta.Codigo === 0) {
                        // CONFORME
                        setMensaje('')
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'formRegistro',
                                category: 'Etapas de Registro',
                                action: 'Paso 1',
                                label: 'Satisfactorio',
                            }
                        })

                        // **************************************
                        // Contuinuar con el alta
                        // navigate('/registro/2')           

                        let saltPassword = btoa(
                            encryptDecrypt(
                                sha256(form.password),
                                tokenUpdate.substring(tokenUpdate.length - 10, tokenUpdate.length)
                            )
                        );

                        let datosUpdate = {
                            fono: rpta.user.fono ? rpta.user.fono : '',
                            idusuario: rpta.user.idusuario,
                            email: rpta.user.email ? rpta.user.email : '',
                            clave: saltPassword,
                            tokenCaptcha: tokenUpdate,
                        }
                        // ****************
                        postUpdate(datosUpdate).then(async res => {
                            if (res.Codigo === 1 || res.Codigo === 2) {
                                setMensaje(res.Mensaje)
                                setLoading(false)
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'formRegistro',
                                        category: 'Etapas de Registro',
                                        action: 'Paso 2',
                                        label: res.Mensaje,
                                    }
                                })
                            }
                            else if (res.Codigo === 0) {
                                setMensaje('')
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'formRegistro',
                                        category: 'Etapas de Registro',
                                        action: 'Paso 2',
                                        label: 'Satisfactorio',
                                        userId: rpta.user.identificacion,
                                        userMail: datosUpdate.email
                                    } 
                                })

                                // PASO 3:....
                                // navigate('/registro/3')
                                // ***************************

                                let objUsuario = {
                                    usuario: encodeBase64(form.email),
                                    password: encodeBase64(encryptDecrypt(sha256(form.password),
                                        tokenLogin.substring(tokenLogin.length - 10, tokenLogin.length)
                                    )),
                                    sitioPassword: "9B9CB6631B27AA1DF47DB351B497004810015B09",
                                    sitioUsuario: "CARDIF",
                                    deviceInfo: '',
                                    tokenCaptcha: tokenLogin,
                                }

                                postLogin(objUsuario).then(rpta => {
                                    if (rpta.codigo === 0) {

                                        localStorage.setItem('token', CryptoJS.AES.encrypt(rpta.data.token, CLI_PUB));

                                        TagManager.dataLayer({
                                            dataLayer: {
                                                event: 'formRegistro',
                                                category: 'Etapas de Registro',
                                                action: 'final',
                                                label: 'Satisfactorio',
                                            }
                                        })

                                        getSession(rpta.data.token).then(data => {
                                            let res = { data }.data
                                            localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(res), CLI_PUB));
                                            addLogs("Iniciar Sesión", "Inicio de sesión exitoso", JSON.stringify(objUsuario));

                                            setLoading(false)
                                            let idUser = getIdUser()
                                            globalIniciarSesion({
                                                globalDataUser: {
                                                    globalUsuId: idUser,
                                                    globalUsuName: res.nombres,
                                                    globalUsuSurnameMat: res.apellidoMaterno,
                                                    globalUsuSurname: res.apellidoPaterno,
                                                    globalUsuCellphone: res.numeroCelular,
                                                    globalUsuEmail: res.correoElectronico,
                                                    globalUsuTipoDoc: res.dicTipoDocumento,
                                                    globalDocIdentidad: res.identificacion,
                                                    globalUsuRole: "",
                                                    globalInvitado: res.invitado,
                                                    globalOrigenIdSocio: res.OrigenIdSocio,
                                                },
                                                globalToken: CryptoJS.AES.encrypt(rpta.data.token, CLI_PUB),
                                                globalTokenUser: CryptoJS.AES.encrypt(JSON.stringify(res), CLI_PUB),
                                                globalAutenticado: true,
                                                globalCargando: false

                                            })

                                            navigate("/ecosistema", {
                                                state: {
                                                    loggedIn: true
                                                }
                                            })
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            } catch (err) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'formRegistro',
                        category: 'Etapas de Registro',
                        action: 'Paso 1',
                        label: 'Error',
                    }
                })

                setLoading(false)

                setMensaje("Error en el registro")
            }

        } else {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'formRegistro',
                    category: 'Etapas de Registro',
                    action: 'Paso 1',
                    label: 'Error',
                }
            })
            setMensaje("Para continuar debes completar todos los campos.")
        }
    }

    return (
        <>
            {
                loading && <SpinnerCuadrado />
            }

            <Offcanvas show={props.show} onHide={handleClose} className='modal__activa-cuenta' placement="end" >
                <ButtonClose close={handleClose} />
                <Offcanvas.Body className='p-0'>
                    <div className="sticky__btn__sidebar_up d-lg-block d-none">
                        <button className="btn__sidebar" type="button" onClick={handleClose} >
                            <b>INGRESA A TU<br/>ECOSISTEMA AQUÍ</b>
                            <i className="fa-solid fa-angle-down flecha__sticky__btn"></i>
                        </button>
                    </div>
                    <div className='col-12'>
                        <h1 className='modal__activa-cuenta__title mb-5'>¡Activar tu cuenta es muy sencillo!</h1>
                    </div>
                    <div className='col-12 modal__activa-cuenta__body'>
                        <p>Complete los datos a continuación para comenzar a activar su cuenta:</p>
                        {
                            mensaje !== '' &&
                            <div className='error__box'>
                                <div className='texto__error'>
                                    <p>{mensaje}</p>
                                </div>
                            </div>
                        }
                        <Form autoComplete="off" onSubmit={(e) => { e.preventDefault()}} className="form__modal" >
                            <div className="row justify-content-center">
                                <div className='col-12'>
                                    <input
                                        type='text'
                                        className='form-control border-input '
                                        id='email'
                                        name='email'
                                        placeholder='Email'
                                        autoComplete="off"
                                        value={form.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-12'>
                                    <input
                                        type='text'
                                        className='form-control border-input '
                                        id='celular'
                                        name='celular'
                                        placeholder='Celular'
                                        autoComplete="off"
                                        value={form.celular}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-12'>
                                    <input
                                        type='password'
                                        className='form-control border-input '
                                        id='password'
                                        name='password'
                                        placeholder='Contraseña'
                                        autoComplete="off"
                                        value={form.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-12'>
                                    <input
                                        type='password'
                                        className='form-control border-input '
                                        id='confirm_password'
                                        name='confirm_password'
                                        placeholder='Confirmar contraseña'
                                        autoComplete="off"
                                        value={form.confirm_password}
                                        onChange={handleChange}
                                    />
                                </div>

                                {/* {formValidador()} */}

                                <div className='col-12 mt-5 pb-5 d-flex caja__activa__label'>
                                    <input type="checkbox" className="activa__checkbox" id="checkboxCondiciones" />
                                    <label className="activa__label">He leído y acepto las condiciones de uso.</label>
                                </div>
                                <div className='col-12 mt-3 text-center'>
                                    <button className="btn__azul" onClick={handleSubmit}>CONTINUAR</button>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey={COD_RECAPTCHA_V3}
                                    />
                                </div>
                                <div className="text-center footer__register mt-5">
                                    <p><span className="link__login" onClick={gotoLogin}>Ya tengo una cuenta</span></p>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default SidebarActivarCuenta